// React
import React, { useEffect, useState } from "react";
// Third party
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  Column,
} from "react-table";
// API
import { expiredTokenValidation } from "../../api/expiredTokenValidation";
// Images
import Arrow from "../../Assets/svg/blue-arrow.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface TableProps {
  columns: Column[];
  additionalDataKey: string; // Key for the additional data in each row
  openModal?: ((value: any) => void) | undefined;
  caption?: string;
  search?: string;
  loading?: boolean;
  fetchData: (
    pageIndex: number,
    pageSize: number,
    sortBy: any,
    globalFilter: string
  ) => Promise<any>;
}

const Table: React.FC<TableProps> = ({
  columns,
  fetchData,
  search,
}) => {
  const [data, setData] = useState<any[]>([]);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [totalElement, setTotalElement] = useState(0);
  const [inputPage, setInputPage] = useState<any>("");
  const navigate = useNavigate();

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    state,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    gotoPage,
    pageCount,
    setPageSize,
    rows,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualSortBy: true,
      manualPagination: true,
      manualGlobalFilter: true,
      pageCount: controlledPageCount,
    } as any,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  const { globalFilter, pageIndex, pageSize, sortBy } = state;

  useEffect(() => {
    gotoPage(0);
    setPageSize(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      try {
        const result = await fetchData(
          pageIndex,
          pageSize,
          sortBy,
          globalFilter
        );
        setData(result.content);
        setControlledPageCount(Math.ceil(result.page.totalPages));
        setTotalElement(Math.ceil(result.page.totalElements));
      } catch (error) {
        expiredTokenValidation(error);
      }
    };

    fetchDataAndUpdateState();
  }, [fetchData, pageIndex, pageSize, sortBy, globalFilter, search]);

  // const handleSortToggle = (column: ColumnTable) => {
  //   const { canSort, toggleSortBy, isSortedDesc } = column;

  //   if (canSort) {
  //     toggleSortBy(!isSortedDesc, false);
  //   }
  // };

  return (
    <React.Fragment>
      {/* Table */}
      <table {...getTableProps()} className="min-w-full">
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <React.Fragment key={row.id}>
                <div className="w-full bg-gray-200 dark:bg-gray-900 py-2">
                  <div className="container-fluid mx-auto flex items-start justify-center">
                    <div className="w-full">
                      <div className="mx-auto w-full bg-table-sub-light dark:bg-gray-800 shadow dark:bg-button-dark">
                        <div className="bg-primary-default justify-between p-3 flex flex-col lg:flex-row items-start lg:items-center dark:bg-sub-table-light dark:text-white">
                          <div className="flex flex-col mt-3 lg:mt-0 md:flex-row items-start md:items-center">
                            {row.original.response.domains?.map(
                              (domain: any, index: number) => (
                                <h2
                                  key={`alert-table-${domain.name}-${index}`}
                                  className="text-md lg:text-md text-primary-dark dark:text-primary-light font-bold"
                                >
                                  {domain.name}
                                  {`${
                                    index !==
                                    row.original.response.domains.length - 1
                                      ? ","
                                      : ""
                                  }`}
                                </h2>
                              )
                            )}
                          </div>
                          <div className="flex gap-2 flex-col mt-3 lg:mt-0 md:flex-row  items-start md:items-center">
                            {row.original?.response.tags?.map(
                              (tag: any, index: number) => (
                                <div className="bg-[#E5E7EB] text-primary-dark font-bold dark:text-primary-light dark:bg-primary-dark rounded text-sm leading-3 py-2 px-3">
                                  {tag.tag}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <div className="justify-between p-2 flex flex-col lg:flex-row items-start lg:items-center px-5">
                          <div className="w-full lg:w-1/3">
                            <h1
                              className="cursor-pointer text-primary-dark dark:text-primary-light font-bold text-xl"
                              onClick={() =>
                                navigate(`/notes/${row.original.response.id}`)
                              }
                            >
                              {row.original.response.title}
                            </h1>
                            <p className="mt-2 text-md text-primary-dark dark:text-primary-light font-bold">
                              {row.original.response.sourceType}
                            </p>
                          </div>
                          <p className="mt-2 text-md text-primary-dark dark:text-primary-light font-bold">
                            {row.original.response.sourceDetail}
                          </p>
                          <div className="w-full lg:w-1/3">
                            <div className="flex justify-end">
                              <span className="group relative cursor-pointer">
                                <div className="absolute bottom-10   -translate-x-[50%] hidden group-hover:block w-[350px]">
                                  <div className="bg-[#fff] text-[#393C51]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  px-[14px] pt-[12px] pb-[17px] rounded-[10px] border border-[#4185EE] ">
                                    <ul className="max-w-md space-y-1 text-dark list-disc list-inside dark:text-white">
                                      <li>
                                        Date Published:{" "}
                                        {moment(
                                          row.original.datePublished
                                        ).format("MM/DD/yyyy hh:mm a")}
                                      </li>
                                      <li>
                                        Date Created:{" "}
                                        {moment(
                                          row.original.dateCreated
                                        ).format("MM/DD/yyyy hh:mm a")}{" "}
                                        {row.original.createdBy?.username}
                                      </li>
                                      <li>
                                        Date Modified:{" "}
                                        {moment(
                                          row.original.dateModified
                                        ).format("MM/DD/yyyy hh:mm a")}{" "}
                                        {row.original.lastModifiedBy?.username}
                                      </li>
                                    </ul>
                                    <div className="bottom-0 right-[40%]  -translate-x-1/2 translate-y-1/2 absolute    rotate-[135deg] transform    w-4 h-4 bg-white border-r border-t border-[#4185EE] dark:bg-primary-dark"></div>{" "}
                                  </div>
                                </div>
                                <h2 className="mt-2 text-primary-dark dark:text-primary-light font-bold text-md leading-6 lg:mt-0 lg:text-end">
                                  {moment(row.original.datePublished).format(
                                    "MM/DD/yyyy hh:mm a"
                                  )}
                                </h2>
                              </span>
                            </div>
                            <p className="mt-2 text-primary-dark dark:text-primary-light text-md leading-5 lg:text-end font-bold">
                              {row.original.response.author1}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col lg:flex-row px-5 pb-8 gap-3 mt-2">
                          <div className="flex flex-col lg:flex-row w-full lg:w-11/12 items-start lg:items-center mb-8 lg:mb-0">
                            <div
                              className="ql-container ql-snow"
                              style={{ position: "relative" }}
                            >
                              <div
                                className="ql-editor"
                                dangerouslySetInnerHTML={{
                                  __html: row.original.response.content,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="flex items-end w-full lg:w-1/12 justify-end lg:justify-end">
                            <button
                              className="text-secondary font-semibold text-base capitalize underline float-right"
                              onClick={() =>
                                navigate(
                                  `/notes/${row.original.response.id}/edit`
                                )
                              }
                            >
                              <div className="rounded-full  p-3 border border-[#E6E6F9] bg-button-light dark:bg-button-dark cursor-pointer">
                                <svg
                                  className="dark:brightness-[3.5] brightness-0"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_1000_6286)">
                                    <path
                                      d="M9.88081 2.68738L1.07744 11.4914C1.03311 11.5359 1.00152 11.5914 0.985954 11.6522L0.010151 15.5688C-0.00420054 15.6269 -0.00332103 15.6877 0.0127046 15.7454C0.0287303 15.8031 0.0593605 15.8557 0.101637 15.8981C0.166572 15.9628 0.254492 15.9992 0.346172 15.9992C0.374454 15.9992 0.402627 15.9957 0.430055 15.9888L4.34662 15.0129C4.40752 14.9976 4.46311 14.966 4.50743 14.9216L13.3116 6.11812L9.88081 2.68738ZM15.4926 1.48695L14.5126 0.507024C13.8577 -0.14793 12.7162 -0.14728 12.0619 0.507024L10.8616 1.70745L14.2922 5.13806L15.4926 3.93767C15.8197 3.61065 16 3.17534 16 2.71239C16 2.24944 15.8197 1.81414 15.4926 1.48695Z"
                                      fill="#4B49AC"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1000_6286">
                                      <rect
                                        width="16"
                                        height="16"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Card code block end */}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="flex flex-col md:flex-row justify-between mt-6 ">
        <div className="mb-4 md:mb-0 md:flex md:justify-between md:items-center">
          <div className="mb-2 md:mb-0">
            <span className="text-primary text-lg font-medium border-r border-primary pr-5">
              Showing {data.length !== 0 && pageIndex * pageSize + 1}{" "}
              {data.length !== 0 && "-"}{" "}
              {pageIndex === pageCount - 1
                ? Math.min((pageIndex + 1) * pageSize, totalElement)
                : Math.min((pageIndex + 1) * pageSize, totalElement)}{" "}
              of {totalElement}
            </span>
          </div>
          <div className="flex items-center gap-4">
            <div className="text-primary text-sm font-normal md:ml-5">
              Show Entries
            </div>
            <div className="relative">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="dark:text-white dark:placeholder:text-white dark:bg-b-secondary-dark dark:border-white border  border-primary rounded-full px-[22px] mr-4 py-[12px] text-primary   appearance-none outline-none cursor-pointer w-[82px]"
              >
                {[10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
              <span className="absolute top-1/2 right-8  -translate-y-1/2">
                <img src={Arrow} alt="dropdown" className="" />
              </span>
            </div>
          </div>
        </div>
        <div className="text-secondary flex items-center mt-4 md:mt-0">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="mr-5"
          >
            <svg
              className="dark:brightness-[4]"
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
            >
              <path
                d="M9.1233 9.87681L12.1644 6.83576L10.6438 5.31524L6.08228 9.87681L10.6438 14.4385L12.1644 12.9179L9.1233 9.87681Z"
                fill="#F3797E"
              />
            </svg>
          </button>
          {Array.from({ length: pageCount }, (_, i) => {
            const pageNumber = i + 1; // Add 1 to start from 1
            if (pageNumber > 0) {
              if (pageCount > 4) {
                if (
                  (pageNumber >= pageIndex && pageNumber <= pageIndex + 2) ||
                  pageNumber === 1 ||
                  pageNumber === pageCount
                ) {
                  return (
                    <button
                      key={i}
                      onClick={() => gotoPage(pageNumber - 1)} // Subtract 1 when calling gotoPage
                      className={`px-2  text-secondary dark:text-primary-light ${
                        pageNumber === pageIndex + 1 ? "border-b-2" : ""
                      }`}
                    >
                      <span>{pageNumber}</span>
                    </button>
                  );
                } else if (
                  (pageIndex >= 3 && pageNumber === 2) ||
                  (pageIndex < pageCount - 2 && pageNumber === pageCount - 1)
                ) {
                  // Render ellipsis for 2nd and 2nd-to-last page when not on the edge
                  return (
                    <span
                      key={`ellipsis${i}`}
                      className="p-2 text-secondary dark:text-primary-light"
                    >
                      ...
                    </span>
                  );
                }
              } else {
                // Render pagination for 3 or fewer pages
                return (
                  <button
                    key={i}
                    onClick={() => gotoPage(pageNumber - 1)} // Subtract 1 when calling gotoPage
                    className={`px-2  text-secondary dark:text-primary-light  ${
                      pageNumber === pageIndex + 1 ? "border-b-2" : ""
                    } `}
                  >
                    <span>{pageNumber}</span>
                  </button>
                );
              }
            }
            return null;
          })}

          <div className="flex items-center">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                gotoPage(Number(inputPage) - 1);
                // setInputPage("");
              }}
            >
              <button
                className="text-secondary dark:text-primary-light hidden md:inline-block"
                type="submit"
              >
                Go{" "}
              </button>
              <input
                type="number"
                className="border border-[#EDEDF7] bg-[#ffffff] text-secondary rounded-md mx-2 max-w-[50px] outline-none px-2 dark:bg-primary-dark dark:text-primary-light"
                value={inputPage}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setInputPage(inputValue);
                }}
              />
            </form>
          </div>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              className="dark:brightness-[4]"
            >
              <path
                d="M9.5537 9.87681L6.51257 6.83576L8.03319 5.31524L12.5947 9.87681L8.03319 14.4385L6.51257 12.9179L9.5537 9.87681Z"
                fill="#F3797E"
              />
            </svg>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Table;
