// Components
import InputField from "../../Common-inputs";
import DeleteTooltip from "../../Delete-Tooltip";
// Types
import { StepBase } from "..";

interface StepFourProps extends StepBase {
  Minus: string;
  Plus: string;
}

export default function StepFour(props: StepFourProps) {
  const {
    association,
    deleteID,
    deleteModal,
    formik,
    handleRemoveAssociation,
    Minus,
    Plus,
    serverError,
    setAssociation,
    setDeleteID,
    setDeleteModal,
  } = props;

  const handleAddOutDates = () => {
    formik.setValues({
      ...formik.values,
      outDates: [
        ...formik.values.outDates,
        {
          dateEvent: null,
          comment: "",
          mnaId:  "0",
        },
      ],
    });
  };

  const handleAddBrokerClosingDates = () => {
    formik.setValues({
      ...formik.values,
      brokerClosingDates: [
        ...formik.values.brokerClosingDates,
        {
          dateEvent: null,
          comment: "",
          mnaId:  "0",
        },
      ],
    });
  };

  const handleAddFinancingDates = () => {
    formik.setValues({
      ...formik.values,
      financingDates: [
        ...formik.values.financingDates,
        {
          dateEvent: null,
          comment: "",
          mnaId:  "0",
        },
      ],
    });
  };


  const handleButtonClick = (buttonValue:any) => {
   formik.setFieldValue("requiredToLitigate",  formik.values.requiredToLitigate === buttonValue ? null : buttonValue)    
  };



  return (
    <>
      {/* MNA Basics */}
      <div className="border border-[#E4E3ED] rounded-lg dark:bg-table-dark bg-table-light">
        <div className="px-[23px] py-[30px]">
          <div className="flex flex-col gap-[30px] xl:flex-row">
            {/* Terms */}
            <div className="w-full xl:w-1/3">
              <InputField
                label="Terms"
                id="terms"
                name="terms"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div>

            {/* Mna Value */}
            <div className="w-full xl:w-1/3">
              <InputField
                label="MNA Value"
                id="size"
                name="size"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div>

            {/* Premium */}
            <div className="w-full xl:w-1/3">
              <InputField
                label="Premium"
                id="premium"
                name="premium"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last={true}
              />
            </div>
          </div>
          <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
            {/* Terms */}
            {/* <div className="w-full xl:w-1/3">
              <InputField
                label="Announced Date"
                id="dateAnnounced"
                name="dateAnnounced"
                type="date"
                placeholder="Select date"
                serverError={serverError || ""}
                formik={formik}
                showIcon
                required={true}
              />
            </div> */}

            {/* Mna Value */}
            {/* <div className="w-full xl:w-1/3">
              <InputField
                label="Closing Date"
                id="dateClosing"
                name="dateClosing"
                type="date"
                placeholder="Select date"
                serverError={serverError || ""}
                formik={formik}
                showIcon
              />
            </div> */}

            {/* Premium */}
            <div className="w-full xl:w-full">
              <InputField
                label="Closing Language"
                id="closingLanguage"
                name="closingLanguage"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last={true}
              />
            </div>
          </div>
          <div className="mt-[30px]">
            {formik.values.brokerClosingDates?.map(
              (outDate: any, index: number) => (
                <div
                  key={index}
                  className={`flex flex-col gap-[30px] xl:flex-row items-center  relative ${
                    index === formik.values.brokerClosingDates?.length - 1
                      ? " "
                      : "mb-[30px]"
                  }`}
                >
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Broker Closing Estimate Date"
                      id={`brokerClosingDates[${index}].dateEvent`}
                      name={`brokerClosingDates${index}.dateEvent`}
                      type="date"
                      placeholder="Select date"
                      serverError={serverError || ""}
                      formik={formik}
                      showIcon
                      isDynamic
                      value={outDate.dateEvent}
                    />
                  </div>

                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Comment"
                      id={`brokerClosingDates[${index}].comment`}
                      name={`brokerClosingDates[${index}].comment`}
                      type="text"
                      placeholder="Enter here..."
                      serverError={serverError || ""}
                      formik={formik}
                      showIcon
                      isDynamic
                      value={outDate.comment}
                    />
                  </div>
                  <div className="w-full xl:w-1/3 relative">
                    <button
                      onClick={() => {
                        setDeleteID(index);
                        setAssociation("brokerClosingDates");
                        setDeleteModal(true);
                      }}
                      type="button"
                      className="text-secondary text-base font-medium flex items-center gap-[6px]"
                    >
                      <img src={Minus} alt="minus" /> Remove
                    </button>
                    {deleteID === index &&
                      association === "brokerClosingDates" && (
                        <DeleteTooltip
                          className="bottom-10"
                          tooltip={deleteModal}
                          setTooltip={setDeleteModal}
                          onDeleteItem={handleRemoveAssociation}
                          top
                        />
                      )}
                  </div>
                </div>
              )
            )}
            <div className="flex justify-center items-center mt-[30px]">
              <button
                type="button"
                data-testid="AddNewPlus"
                onClick={handleAddBrokerClosingDates}
                className="text-secondary text-lg py-4  font-bold flex items-center justify-center  border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:text-white"
              >
                <img src={Plus} alt="plus" className="dark:brightness-200" />{" "}
                Add new
              </button>
            </div>
          </div>
          <div className="mt-[30px]">
            {formik.values.financingDates?.map(
              (outDate: any, index: number) => (
                <div
                  key={index}
                  className={`flex flex-col gap-[30px] xl:flex-row items-center  relative ${
                    index === formik.values.financingDates?.length - 1
                      ? " "
                      : "mb-[30px]"
                  }`}
                >
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Financing Date"
                      id={`financingDates[${index}].dateEvent`}
                      name={`financingDates${index}.dateEvent`}
                      type="date"
                      placeholder="Select date"
                      serverError={serverError || ""}
                      formik={formik}
                      showIcon
                      isDynamic
                      value={outDate.dateEvent}
                    />
                  </div>

                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Comment"
                      id={`financingDates[${index}].comment`}
                      name={`financingDates[${index}].comment`}
                      type="text"
                      placeholder="Enter here..."
                      serverError={serverError || ""}
                      formik={formik}
                      showIcon
                      isDynamic
                      value={outDate.comment}
                    />
                  </div>
                  <div className="w-full xl:w-1/3 relative">
                    <button
                      onClick={() => {
                        setDeleteID(index);
                        setAssociation("financingDates");
                        setDeleteModal(true);
                      }}
                      type="button"
                      className="text-secondary text-base font-medium flex items-center gap-[6px]"
                    >
                      <img src={Minus} alt="minus" /> Remove
                    </button>
                    {deleteID === index &&
                      association === "financingDates" && (
                        <DeleteTooltip
                          className="bottom-10"
                          tooltip={deleteModal}
                          setTooltip={setDeleteModal}
                          onDeleteItem={handleRemoveAssociation}
                          top
                        />
                      )}
                  </div>
                </div>
              )
            )}
            <div className="flex justify-center items-center mt-[30px]">
              <button
                type="button"
                onClick={handleAddFinancingDates}
                className="text-secondary text-lg py-4  font-bold flex items-center justify-center  border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:text-white"
                data-testid="plusAddnew"
              >
                <img src={Plus} alt="plus" className="dark:brightness-200" />{" "}
                Add new
              </button>
            </div>
          </div>
          {/* Break Fee */}
          {/* <div className="w-full mt-[30px]">
            <InputField
              label="Break Fee"
              id="breakFeeAcquirer"
              name="breakFeeAcquirer"
              serverError={serverError || ""}
              type="textarea"
              placeholder="Enter here..."
              formik={formik}
              showIcon
              rows={1}
              last={true}
            />
          </div> */}

          {/* Break Fee */}
          {/* <div className="w-full mt-[30px]">
            <InputField
              label="Reverse Break Fee"
              id="breakFeeTarget"
              name="breakFeeTarget"
              type="textarea"
              placeholder="Enter here..."
              formik={formik}
              serverError={serverError || ""}
              showIcon
              rows={1}
              last={true}
            /> */}
          {/* </div> */}
          <div className="mt-[30px]">
            <div
              className={`flex flex-col gap-[30px] xl:flex-row items-center`}
            ></div>
            {formik.values.outDates?.map((outDate: any, index: number) => (
              <div
                key={index}
                className={`flex flex-col gap-[30px] xl:flex-row items-center  relative ${
                  index === formik.values.outDates?.length - 1
                    ? " "
                    : "mb-[30px]"
                }`}
              >
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Out Date"
                    id={`outDates[${index}].dateEvent`}
                    name={`outDates${index}.dateEvent`}
                    type="date"
                    placeholder="Select date"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    isDynamic
                    value={outDate.dateEvent}
                  />
                </div>

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Comment"
                    id={`outDates[${index}].comment`}
                    name={`outDates[${index}].comment`}
                    type="text"
                    placeholder="Enter here..."
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    isDynamic
                    value={outDate.comment}
                  />
                </div>
                <div className="w-full xl:w-1/3 relative">
                  <button
                    onClick={() => {
                      setDeleteID(index);
                      setAssociation("outDates");
                      setDeleteModal(true);
                    }}
                    type="button"
                    className="text-secondary text-base font-medium flex items-center gap-[6px]"
                  >
                    <img src={Minus} alt="minus" /> Remove
                  </button>
                  {deleteID === index && association === "outDates" && (
                    <DeleteTooltip
                      className="bottom-10"
                      tooltip={deleteModal}
                      setTooltip={setDeleteModal}
                      onDeleteItem={handleRemoveAssociation}
                      top
                    />
                  )}
                </div>
              </div>
            ))}
            <div className="flex justify-center items-center mt-[30px]">
              <button
                type="button"
                onClick={handleAddOutDates}
                className="text-secondary text-lg py-4  font-bold flex items-center justify-center  border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:text-white"
              >
                <img src={Plus} alt="plus" className="dark:brightness-200" />{" "}
                Add new
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
            <div className="w-full xl:w-1/2">
              <InputField
                label="Required to Litigate Comment"
                id="requiredToLitigateComment"
                name="requiredToLitigateComment"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last
              />
            </div>
            <div className="w-full xl:w-1/2">
              <div className="flex justify-between items-center">
                <label
                  htmlFor="Content"
                  className="text-base  text-[#393C51]  dark:text-white  font-medium mb-2 flex gap-1"
                >
                  Required to Litigate
                </label>
                <div className="tooltip2">
                  <div
                    className={`bg-[#fff]  bottom-9   text-[#393C51]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  z-50 tooltiptext w-[328px] px-[14px] pt-[12px] pb-[17px] rounded-[10px] border dark:before:border-t-primary-dark ${
                      formik.errors?.requiredToLitigate
                        ? "border-[#DB1A21] after:border-transparent after:border-t-[#DB1A21] before:top-[97%]"
                        : "border-[#4185EE] after:border-transparent after:border-t-[#4185EE]  before:top-[99%]"
                    }  tooltiptext2`}
                  >
                    {formik.errors?.requiredToLitigate
                      ? formik.errors?.requiredToLitigate
                      : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}
                  </div>
                  <svg
                    className={"dark:brightness-[4]"}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                  >
                    <path
                      d="M9 0.5C4.02571 0.5 0 4.52525 0 9.5C0 14.4742 4.02525 18.5 9 18.5C13.9743 18.5 18 14.4747 18 9.5C18 4.52571 13.9747 0.5 9 0.5ZM9 17.2442C4.72985 17.2442 1.25582 13.7702 1.25582 9.5C1.25582 5.22982 4.72985 1.75582 9 1.75582C13.2702 1.75582 16.7442 5.22982 16.7442 9.5C16.7442 13.7702 13.2702 17.2442 9 17.2442Z"
                      fill={` ${
                        formik.errors?.requiredToLitigate
                          ? "#DB1A21"
                          : "#1E87F0"
                      }`}
                    />
                    <path
                      d="M8.73354 11.8881C8.23597 11.8881 7.83319 12.3027 7.83319 12.8002C7.83319 13.286 8.22413 13.7125 8.73354 13.7125C9.24295 13.7125 9.6457 13.286 9.6457 12.8002C9.6457 12.3027 9.23107 11.8881 8.73354 11.8881ZM8.88752 4.98145C7.28819 4.98145 6.55371 5.92922 6.55371 6.56893C6.55371 7.03095 6.94465 7.24421 7.2645 7.24421C7.90424 7.24421 7.64363 6.33197 8.85198 6.33197C9.44429 6.33197 9.9182 6.59262 9.9182 7.13758C9.9182 7.77728 9.25477 8.14452 8.86383 8.47622C8.52025 8.77234 8.07011 9.2581 8.07011 10.2769C8.07011 10.8929 8.23597 11.0706 8.72166 11.0706C9.30212 11.0706 9.4206 10.81 9.4206 10.5849C9.4206 9.96889 9.43245 9.61349 10.084 9.10408C10.4039 8.85531 11.4108 8.04971 11.4108 6.93613C11.4108 5.82256 10.4039 4.98145 8.88752 4.98145Z"
                      fill={` ${
                        formik.errors?.requiredToLitigate
                          ? "#DB1A21"
                          : "#1E87F0"
                      }`}
                    />
                  </svg>{" "}
                </div>
              </div>
              <div className="flex space-x-4 justify-between">
                <button
                  onClick={() => handleButtonClick(true)}
                  type="button"
                  className={`w-full px-6 py-3 rounded-full font-semibold transition-all duration-300 border ${
                    formik.values.requiredToLitigate === true
                      ? "bg-success text-white shadow-lg transform scale-105 border-success"
                      : "border-[#C3C1DF] text-secondary bg-[#FAFAFE] dark:bg-button-dark dark:text-white hover:bg-success hover:text-white hover:border-success"
                  }`}
                >
                  True
                </button>
                <button
                  onClick={() => handleButtonClick(false)}
                  type="button"
                  className={`w-full px-6 py-3 rounded-full font-semibold transition-all duration-300 border ${
                    formik.values.requiredToLitigate === false
                      ? "bg-danger text-white shadow-lg transform scale-105 border-danger"
                      : "border-[#C3C1DF] text-secondary bg-[#FAFAFE] dark:bg-button-dark dark:text-white hover:bg-danger hover:text-white hover:border-danger"
                  }`}
                >
                  False
                </button>
              </div>
            </div>
            {/* <div className="w-full xl:w-1/3">
              <InputField
                label="Tax"
                id="tax"
                name="tax"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                showIcon
                serverError={serverError || ""}
              />
            </div> */}
            {/* <div className="w-full xl:w-1/3">
              <InputField
                label="Proxy Solicitors"
                id="proxySolicitors"
                name="proxySolicitors"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                showIcon
                serverError={serverError || ""}
                last={true}
              />
            </div> */}
            {/* </div> */}
            {/* <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
            <div className="w-full xl:w-1/3">
              <InputField
                label="Information Agent"
                id="informationAgent"
                name="informationAgent"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div> */}
            {/* <div className="w-full xl:w-1/3">
              <InputField
                label="ADR Depositary"
                id="ADRDepositary"
                name="ADRDepositary"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div> */}
            {/* <div className="w-full xl:w-1/3">
              <InputField
                label="Guaranteed Delivery"
                id="guaranteedDelivery"
                name="guaranteedDelivery"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last={true}
              />
            </div> */}
          </div>
          <div className="flex flex-col gap-[30px] xl:flex-row mt-[30px]">
            <div className="w-full xl:w-1/2">
              <InputField
                label="Appraisal Right"
                id="appraisalRights"
                name="appraisalRights"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
              />
            </div>
            <div className="w-full xl:w-1/2">
              <InputField
                label="Specific Performance"
                id="specificPerformance"
                name="specificPerformance"
                type="text"
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
