// React
import React, { useEffect, useRef, useState } from "react";
// Third party
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
// Components
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Loader from "../Components/Loader";
// OpenAPI
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { DashboardRestControllerApi } from "../openapi";
import { DateEventRestControllerApi } from "../openapi";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
// Types
import { Mna, Note, Upcoming } from "../interfaces/pages/variedInterfaces";
// Images
import dashboardIcon from "../Assets/svg/blue-dashboard.svg";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState<any>([]);
  const [hoveredEvent, setHoveredEvent] = useState<Upcoming | null>(null);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [dropdownOpen, setDropdownOpen] = useState("");
  const dropdown = useRef<any>();
  const config = apiConfig();

  useEffect(() => {
    fetchDashboardList();
    fetchUpcomingEvents();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = ({ target }: MouseEvent) => {
    if (dropdown.current && !dropdown.current.contains(target as Node)) {
      setDropdownOpen("");
    }
  };

  const fetchDashboardList = async () => {
    setLoading(true);
    try {
      
      const api = new DashboardRestControllerApi(config);
      const response = await api.getDashboard({ headers: config.baseOptions.headers });

      setData(response.data);
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  const fetchUpcomingEvents = async () => {
    setLoading(true);
    try {
      const api = new DateEventRestControllerApi(config);
      const response = await api.getUpcomingDateEvents1({ headers: config.baseOptions.header });
      setUpcomingEvents(response.data);
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  const handleMouseEnter = (event: Upcoming, eventPosition: { x: number, y: number }) => {
    setHoveredEvent(event);
    setPopupPosition(eventPosition);
  };

  const handleMouseLeave = () => {
    setHoveredEvent(null);
    setPopupPosition({ x: 0, y: 0 });
  };

  return (
    <React.Fragment>
      <div className="p-[2rem]">
        <Breadcrumbs breadcrumbs={["Dashboard"]} icon={dashboardIcon} />
        <div className="flex flex-col lg:flex-row gap-[30px] mt-[29px]">
          <div className="lg:w-1/2 flex flex-col relative">
            {/* List Group 1 */}
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                  <table className="min-w-full dark:bg-table-dark bg-table-light">
                    <caption
                      className="text-left text-[18px] font-semibold bg-primary-default dark:bg-table-dark text-[#FFF] py-3 px-[29px]">
                      Top Notes
                    </caption>
                    <thead className="bg-table-sub-light dark:bg-black dark:text-white">
                      <tr>
                        <th scope="col"
                          className="px-[29px] py-3 text-start text-primary font-semibold text-lg w-3/6">
                          Title
                        </th>
                        <th scope="col"
                          className="px-[29px] py-3 text-start text-primary font-semibold text-lg">
                          Date
                        </th>
                        <th scope="col"
                          className="py-3 text-start text-primary font-semibold text-lg">
                          Source Type
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-[#DCDBEE] align-top">
                      {data.notes?.map((note: Note, index: number) => (
                        <tr key={index}>
                          <td className="px-[30px] py-5 text-secondary dark:text-white font-bold text-base break-words">
                            <Link to={`/notes/${note?.id}`}>
                              {note.title}
                            </Link>
                          </td>
                          <td className="px-[30px] py-5  text-[#393C51]  dark:text-white  font-normal text-base">
                            {moment(note.dateCreated).format("MM/DD/YYYY hh:mm A")}
                          </td>
                          <td
                            scope="row"
                            className="px-[30px] py-5  text-[#393C51]  dark:text-white  font-normal text-base"
                          >
                            {note?.sourceType}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* List Group 2 */}
            <div className="mt-6">
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                    <table className="min-w-full dark:bg-table-dark bg-table-light">
                      <caption
                        className="text-left text-[18px] font-semibold bg-primary-default dark:bg-table-dark text-[#FFF] py-3 px-[29px]">
                        Mna Quick Links
                      </caption>
                      <thead className="bg-table-sub-light dark:bg-black dark:text-white">
                        <tr>
                          <th scope="col"
                            className="px-[29px] py-3 text-start text-primary font-semibold text-lg">
                            Mna Name
                          </th>
                          <th scope="col"
                            className="px-[29px] py-3 text-start text-primary font-semibold text-lg">
                            Category
                          </th>
                          <th scope="col"
                            className="px-[29px] py-3 text-start text-primary font-semibold text-lg">
                            Date Announced
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-[#DCDBEE] align-top">
                        {data.mnas?.map((mna: Mna, index: number) => (
                          <tr key={index}>
                            <td
                              className="px-[30px] py-5 text-secondary dark:text-white font-bold text-base cursor-pointer break-words"
                              onClick={() => navigate(`/mna/${mna?.id}`)}
                            >
                              {mna.name}
                            </td>
                            <td className="px-[30px] py-5  text-[#393C51]  dark:text-white  font-normal text-base break-words">
                              {mna.categoryName}
                            </td>
                            <td className="px-[30px] py-5  text-[#393C51]  dark:text-white  font-normal text-base break-words">
                              {moment(mna.dateAnnounced).format("MM/DD/YYYY hh:mm A")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 flex flex-col relative">
            {/* Upcoming Events Table */}
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                  <table className="min-w-full dark:bg-table-dark bg-table-light">
                    <caption
                      className="text-left text-[18px] font-semibold bg-primary-default dark:bg-table-dark text-[#FFF] py-3 px-[29px]">
                      Upcoming Events
                    </caption>
                    <thead className="bg-table-sub-light dark:bg-black dark:text-white">
                      <tr>
                        <th scope="col"
                          className="px-[29px] py-3 text-start text-primary font-semibold text-lg">
                          Date
                        </th>
                        <th scope="col"
                          className="px-[29px] py-3 text-start text-primary font-semibold text-lg">
                          Date Event Type
                        </th>
                        <th scope="col"
                          className="px-[29px] py-3 text-start text-primary font-semibold text-lg">
                          MNA Name
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-[#DCDBEE] align-top">
                      {upcomingEvents.content?.map((upcoming: Upcoming, index: number) => (
                        <tr key={index}>
                          <td
                            className="px-[30px] py-5 text-[#393C51]  dark:text-white  font-normal text-base break-words"
                            onMouseEnter={(e) => handleMouseEnter(upcoming, {
                              x: e.clientX,
                              y: e.clientY
                            })}
                            onMouseLeave={handleMouseLeave}
                          >
                            {moment(upcoming.dateEvent).format("MM/DD/YYYY")}
                          </td>
                          <td
                            className="px-[30px] py-5 text-[#393C51]  dark:text-white  font-normal text-base break-words"
                            onMouseEnter={(e) => handleMouseEnter(upcoming, {
                              x: e.clientX,
                              y: e.clientY
                            })}
                            onMouseLeave={handleMouseLeave}
                          >
                            {upcoming.dateEventTypeName}
                          </td>
                          <td className="px-[30px] py-5 text-secondary dark:text-white font-bold text-base cursor-pointer break-words"
                            onClick={() => navigate(`/mna/${upcoming?.mnaId}`)}>
                            {upcoming.mnaName}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {hoveredEvent && hoveredEvent.comment && (
        <div className="absolute" style={{ top: popupPosition.y, left: popupPosition.x }}>
          <div className="bg-white border-2 border-indigo-500/100 p-4 shadow-md rounded-md relative dark:bg-primary-dark">
            <p>Comment: {hoveredEvent.comment}</p>
          </div>
        </div>
      )}
      {loading && <Loader loading={loading} />}
    </React.Fragment> 
  );
};

export default Dashboard;
