import React, { useState } from "react";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import dashboardIcon from "../Assets/svg/blue-dashboard.svg";
import MNAsDM from "../Assets/svg/mnas-data-migration.svg";
import NotesDM from "../Assets/svg/notes-data-migration.svg";
import RegulatoryLibraryDM from "../Assets/svg/mnas-data-migration.svg";
import FundamentalsDM from "../Assets/svg/regulatory-data-migration.svg";
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { MigrationButton } from "../Components/Data-Migration/Data-Migration-Button";
import { MigrationCard } from "../Components/Data-Migration/Data-Migration-Card";

type MigrationEventData = {
  id: number;
  name: string;
  progress: number;
  timeRemaining: number;
  timeElapsed: number;
};

const MigrationControl = [
  { id: "1", title: "MNAs", icon: MNAsDM, apiEndpoint: "/api/mnas/migrate" },
  { id: "2", title: "Notes", icon: NotesDM, apiEndpoint: "/api/notes/migrate" },
  {
    id: "3",
    title: "Regulatory Library",
    icon: RegulatoryLibraryDM,
    apiEndpoint: "/api/regulatory/migrate",
  },
  {
    id: "4",
    title: "Fundamentals",
    icon: FundamentalsDM,
    apiEndpoint: "/api/fundamentals/migrate",
  },
];

const MigrationStatus = [
  { id: "1", title: "MNAs", icon: MNAsDM, apiEndpoint: "/api/migrations/mnas" },
  {
    id: "2",
    title: "Notes",
    icon: NotesDM,
    apiEndpoint: "/api/migrations/notes",
  },
  {
    id: "3",
    title: "Regulatory Library",
    icon: RegulatoryLibraryDM,
    apiEndpoint: "/api/migrations/regulatory",
  },
  {
    id: "4",
    title: "Fundamentals",
    icon: FundamentalsDM,
    apiEndpoint: "/api/migrations/fundamentals",
  },
];

export const msToMinutes = (ms: number) => Math.round(ms / 60000000);
const toJSON = (value: string | undefined) => {
  if (!value) return;

  try {
    const data = value.replace(/data:/, "");
    return JSON.parse(data) as MigrationEventData;
  } catch (err) {
    console.error("Error parsing response:", err);
  }
};

export type Status = "loading" | "completed" | "not-started" | "failed";

export default function DataMigration() {
  const [migrationProgress, setMigrationProgress] = useState<{
    [key: string]: MigrationEventData;
  }>({});
  const [status, setStatus] = useState<{ [key: string]: Status }>({});
  const [controller, setController] = useState<{
    [key: string]: AbortController;
  }>({});

  const config = apiConfig();

  // Function to handle the start of migration
  const startMigration = async (domain: string) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      [domain]: "loading",
    }));

    const controller = new AbortController();
    setController((prevController) => ({
      ...prevController,
      [domain]: controller,
    }));

    const { apiEndpoint } = MigrationControl.find(
      ({ title }) => title === domain
    )!;

    const { basePath, baseOptions } = config;
    const url = new URL(apiEndpoint, basePath);

    fetch(url, {
      ...baseOptions,
      signal: controller.signal,
      method: "PUT",
    })
      .then(async ({ statusText, ok, body }) => {
        if (!ok) throw new Error(`Failed to start migration: ${statusText}`);
        if (!body) throw new Error("No response body");

        const stream = body.pipeThrough(new TextDecoderStream());
        const reader = stream.getReader();

        if (reader) {
          let lastProgress = 0;

          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              setStatus((prevStatus) => ({
                ...prevStatus,
                [domain]: lastProgress !== 100 ? "failed" : "completed",
              }));

              break;
            } else if (!value.trim()) continue;
            else {
              const data = toJSON(value);
              if (!data) continue;

              setMigrationProgress((prevProgress) => ({
                ...prevProgress,
                [domain]: data,
              }));

              lastProgress = data?.progress || 0;
            }
          }
        }
      })
      .catch((error) => {
        setStatus((prevStatus) => ({
          ...prevStatus,
          [domain]: "failed",
        }));

        if (error.name === "AbortError") return;
        console.error("Error occurred during migration:", error);
      });
  };

  return (
    <React.Fragment>
      <div className="p-[2rem] flex flex-col gap-7">
        <Breadcrumbs breadcrumbs={["Data Migration"]} icon={dashboardIcon} />
        <div className="flex flex-col gap-6">
          {/* Migration Control Section */}
          <div className="flex flex-col gap-5 bg-white rounded-lg p-7">
            <h3 className="text-[#181825] font-extrabold text-xl">
              Migration Control
            </h3>
            <div className="grid gap-2 md:grid-cols-2 xl:grid-cols-4">
              {MigrationControl.map((item) => (
                <MigrationButton
                  key={item.id}
                  item={item}
                  status={status[item.title] || "not-started"} 
                  startMigration={startMigration}
                />
              ))}
            </div>
          </div>

          {/* Migration Status Section */}
          <div className="flex flex-col gap-5 bg-white rounded-lg p-7">
            <h3 className="text-[#181825] font-extrabold text-xl">
              Migration Status Area
            </h3>
            <div className="grid gap-2 lg:grid-cols-2">
              {MigrationStatus.map(({ title, id, icon }) => {
                const progressData = migrationProgress[title] || {
                  progress: 0,
                  timeElapsed: 0,
                  timeRemaining: 0,
                };

                return (
                  <MigrationCard
                    key={id}
                    status={status[title] || "not-started"}
                    id={id}
                    title={title}
                    icon={icon}
                    timeElapsed={progressData.timeElapsed}
                    timeRemaining={progressData.timeRemaining}
                    progress={progressData.progress}
                    startMigration={startMigration}
                    controller={controller[title]}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
