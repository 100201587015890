// Third party
import { AxiosResponse } from "axios";
// API
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { UserProfileRestControllerApi } from "../openapi/api";

export const getUsernameAsync = async () => {
  const api = new UserProfileRestControllerApi(apiConfig());
  const response: AxiosResponse = await api.whoAmI();
  
  // Extract profile data and fallback to username if necessary
  const profile = response?.data.profile || {};
  const nameFirst = profile.nameFirst || "";
  const nameLast = profile.nameLast || "";
  const name = nameFirst && nameLast ? `${nameFirst} ${nameLast}` : response?.data.username || "";
  
  const role = response?.data?.roles[0];
  localStorage.setItem("role", role);

  return name;
};
