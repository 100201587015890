// React
import React, { useEffect, useRef, useState } from "react";
// Components
import AsyncSelect from "../../Async-Select";
import InputField from "../../Common-inputs";
// OpenAPI
import { apiConfig } from "../../ConfigurationApi/Configuration";
import { getApiData } from "../../../Controller/getApiDataConfiguration";
import { TagRestContollerApi } from "../../../openapi";
// Types
import { StepBase } from "..";
import TextEditor from "../../TextEditor";
import ReactQuill from "react-quill";

interface StepTwoProps extends StepBase {
  mnaCategoryList: any;
  mnaSubCategoryList: any;
  onEditorRef?:any
}

export default function StepTwo(props: StepTwoProps) {
  const { formik, serverError, mnaCategoryList, mnaSubCategoryList, onEditorRef } = props;
  const [tagLoading, setMnaLoading] = useState(false);
  const [nameValue] = useState(`${formik.values.tickerTarget}/${formik.values.tickerAcquirer}`)
  const $description = useRef<ReactQuill>(null);


  useEffect(() =>{
    if(!formik.values.name){
      formik.setFieldValue("name", nameValue)
    }
  },[formik.values.name, formik, nameValue])

  const fetchTags = async (inputValue: string): Promise<any[]> => {
    // Simulate an asynchronous call with a delay
    setMnaLoading(true);
    try {
      const api = new getApiData();
      setMnaLoading(false);
      return api.getTagRestContollerApi(inputValue);
    } catch (error) {
      console.error("Error fetching options:", error);
      setMnaLoading(false);
      return [];
    }
  };

  const postTag = async (tag: string) => {
    try {
      const api = new TagRestContollerApi(apiConfig());
      await api.createTag({ tag });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="border border-[#E4E3ED] rounded-lg dark:bg-table-dark bg-table-light">
      <div className="px-[23px] py-[30px]">
        <div className="flex flex-col gap-[30px] xl:flex-row">
          {/* Name */}

          <div className="w-full xl:w-2/4">
            <InputField
              label="Name"
              id="name"
              name="name"
              type="text"
              placeholder="Enter here..."
              serverError={serverError || ""}
              formik={formik}
              showIcon
              required={true}
              value={formik.values?.name || nameValue}
              isDynamic
              onChange={(e) => {
                formik.setFieldValue("name", e.target.value);
              }}
            />
          </div>

          {/* dateAnnounced */}

          <div className="w-full xl:w-2/4">
            <InputField
              label="Date Announced"
              id="dateAnnounced"
              name="dateAnnounced"
              type="date"
              placeholder="Enter here..."
              serverError={serverError || ""}
              formik={formik}
              showIcon
            />
          </div>

          {/* Category */}
          <div className="w-full xl:w-2/4">
            <InputField
              label="Category"
              id="categoryId"
              name="categoryId"
              type="select"
              placeholder="Category"
              optionList={mnaCategoryList || []}
              labelKey="name"
              valueKey="id"
              serverError={serverError || ""}
              formik={formik}
              showIcon
              required={true}
            />
          </div>

          {/* Sub-Category */}
          <div className="w-full xl:w-2/4">
            <InputField
              label="Sub-Category"
              id="subCategoryId"
              name="subCategoryId"
              type="select"
              optionList={mnaSubCategoryList || []}
              labelKey="name"
              serverError={serverError || ""}
              valueKey="id"
              placeholder="Sub-Category"
              formik={formik}
              showIcon
              last={true}
            />
          </div>
        </div>

        {/* Description */}
        <div className="w-full mt-[30px] ">
          <TextEditor
            formik={formik}
            label="Description"
            id="description"
            name="description"
            quillRef={$description}
            onEditorRef={onEditorRef}
          />
        </div>
        <div className="flex flex-col gap-[30px] xl:flex-row">
          <div className="w-full mt-[30px] xl:w-1/2">
            <InputField
              label="Competitors"
              id="competitors"
              name="competitors"
              type="text"
              placeholder="Enter here..."
              serverError={serverError || ""}
              formik={formik}
              showIcon
              value={formik.values?.competitors}
            />
          </div>
          <div className="w-full mt-[30px] xl:w-1/2">
            <InputField
              label="Customers"
              id="customers"
              name="customers"
              type="text"
              placeholder="Enter here..."
              serverError={serverError || ""}
              formik={formik}
              showIcon
              last    
              value={formik.values?.customers}
            />
          </div>
        </div>
        <div className="w-full mt-[30px]">
          <AsyncSelect
            label="Tags"
            name="tags"
            id="tags"
            placeholder="Tags"
            fetchOptions={fetchTags}
            formik={formik}
            loading={tagLoading}
            error={serverError || ""}
            onkeydown={(e) => {
              if (e.code === "Enter") {
                postTag(e.target.value);
              }
            }}
            isMulti
            last
            noOptionsMessage="No tag found"
            tags
          />
        </div>
      </div>
    </div>
  );
}
