// React
import React, { useCallback, useState } from "react";
// Commponents
import { Columns } from "../Components/Columns/Columns";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Table from "../Components/Tables/Mna-Table";
// OpenAPI
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { FileRestControllerApi } from "../openapi";
// Helpers
import { getSortArray } from "../helpers/getSortArray";
// Types
import { Sort, ResponseFile } from "../interfaces/pages/variedInterfaces";
// Images
import fileIcon from "../Assets/svg/blue-file.svg";

const FileList = () => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchMnaList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      setLoading(true);
      try {
        const api = new FileRestControllerApi(apiConfig());

        const response = search
          ? await api.findFiles(`filename:${search}`, undefined, undefined, undefined)
          : await api.findFiles("", pageIndex, pageSize, getSortArray(sortBy));

        return response.data;
      } catch (error) {
        expiredTokenValidation(error);
      } finally {
        setLoading(false);
      }
    },
    [search]
  );

  const handleFileDownload = async (id: string, type: string) => {
    try {
      // Set Authorization header
      const headers: {} = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const api = new FileRestControllerApi(apiConfig());
      const response = await api.downloadFile(id);
      const imageUrl = (response as ResponseFile).config.url;

      // Getting the image url
      const imageResponse = await fetch(imageUrl, { headers });

      // If the request image response it's ok create Blob with the imageResponse
      if (imageResponse.ok) {
        const imageBlob = await imageResponse.blob();

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(imageBlob);

        // Config file name
        const parts = type.split(".");
        const fileName = parts[0];
        downloadLink.download = `${fileName}.${parts[1] || "png"}`;

        // Add url at document body and simulate click to start the donwload
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        return;
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
      <div className="p-[30px]">
        <Breadcrumbs breadcrumbs={["Files"]} icon={fileIcon} onFilter={(value) => setSearch(value)} />
        <div className="mt-[31px]">
          <Table
            columns={columnsInstance.FileList(handleFileDownload)}
            fetchData={fetchMnaList}
            search={search}
            loading={loading}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default FileList;
