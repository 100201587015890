// React
import React, { useCallback, useEffect, useState } from "react";
// Third party
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
// Componenets
import { Columns } from "../Components/Columns/Columns";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Loader from "../Components/Loader";
import Table from "../Components/Tables/Recent-event";
// OpenAPI
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { getApiData } from "../Controller/getApiDataConfiguration";
// Utils
import { formatAndValidateDate, getFileSize } from "../utils";
// Types
import {
  Sort,
  Item,
  ItemMetaData,
  ResponseFile,
} from "../interfaces/pages/variedInterfaces";
// Images
import Close from "../Assets/svg/clsoe.svg";
import { FileRestControllerApi, SectorRestControllerApi } from "../openapi";
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { IConditions } from "../types/mna-types";

const MnaDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState<string | undefined>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabsContent = [
    {
      header: "What Matters",
      content: (
        <>
          <div className=" px-3.5 pt-[16px] pb-2 bg-table-light dark:bg-table-dark text-title text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
            What Matters
          </div>
          <div className="bg-table-light dark:bg-table-dark rounded-bl-md rounded-br-md overflow-hidden">
            <ul className="list-disc pl-8 pt-2">
              {data?.whatMatters?.map(
                (item: Item, index: number) =>
                  item?.note && (
                    <li key={`what-matters-${index}`} className="text-sm">
                      <div
                        className="pb-2.5"
                        dangerouslySetInnerHTML={{ __html: item?.note || "" }}
                      />
                    </li>
                  )
              )}
            </ul>
          </div>
        </>
      ),
    },
    {
      header: "Company Description",
      content: (
        <table className="min-w-full">
          <caption className="text-left text-[18px] font-semibold  text-title pt-4 pb-2 px-4">
            Company Descriptions
          </caption>
          <thead>
            <tr>
              <th scope="col" className="w-1/3"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody className="">
            <tr>
              <td className="pl-4 pr-3.5 py-2.5  text-[#696868] dark:text-white   text-sm font-normal">
                Descriptions:
              </td>
              <td className="pr-4 pl-3.5 py-2.5  text-[#000817] dark:text-white   text-sm font-medium text-left">
                {data?.description}
              </td>
            </tr>
            <tr>
              <td className="pl-4 pr-3.5  text-[#696868] dark:text-white   text-sm font-normal">
                Competitors:
              </td>
              <td className="pl-4 pr-3.5  text-[#000817] dark:text-white   text-sm font-medium text-left">
                {data?.competitors}
              </td>
            </tr>
            <tr>
              <td className="pl-4 pr-3.5 py-2.5  text-[#696868] dark:text-white   text-sm font-normal">
                Customers:
              </td>
              <td className="pr-4 pl-3.5 py-2.5  text-[#000817] dark:text-white   text-sm font-medium text-left">
                {data?.customers}
              </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      header: "Blomberg info",
      content: <></>,
    },
  ];
  useEffect(() => {
    fetchMnaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFeatures = async (): Promise<any[]> => {
    try {
      const api = new getApiData();
      return api.getFeatureControllerApi();
    } catch (error) {
      console.error("Error fetching options:", error);
      expiredTokenValidation(error);
      return [];
    }
  };

  const fetchSectorNameById = async (sectorId: number) => {
    try {
      const api = new SectorRestControllerApi(apiConfig());
      const response = await api.getSectorById(sectorId);
      return response.data?.name || "";
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  const fetchMnaData = async () => {
    setLoading(true);
    try {
      const api = new getApiData();
      const response = await api.getMnaRestControllerApi2(id, fetchFeatures);
      // Check if sectorAcquirer is a number and not null or empty string
      if (
        !isNaN(Number(response.sectorAcquirer)) &&
        response.sectorAcquirer !== null &&
        response.sectorAcquirer !== ""
      ) {
        response.sectorAcquirer = await fetchSectorNameById(
          Number(response.sectorAcquirer)
        );
      }

      // Check if sectorTarget is a number and not null or empty string
      if (
        !isNaN(Number(response.sectorTarget)) &&
        response.sectorTarget !== null &&
        response.sectorTarget !== ""
      ) {
        response.sectorTarget = await fetchSectorNameById(
          Number(response.sectorTarget)
        );
      }
      setData(response);
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  const columnsInstance = new Columns();

  const fetchMnaNoteList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      const getFetchMnaNoteList = new getApiData();
      return getFetchMnaNoteList.getNoteRestControllerApi2(
        String(id),
        pageIndex,
        pageSize,
        sortBy,
        "Mna"
      );
    },
    [id]
  );

  const openModal = (content: string) => {
    setContent(content);

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFileDownload = async (id: string, type: string) => {
    try {
      // Set Authorization header
      const headers: {} = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const api = new FileRestControllerApi(apiConfig());
      const response = await api.downloadFile(id);
      const imageUrl = (response as ResponseFile).config.url;

      // Getting the image url
      const imageResponse = await fetch(imageUrl, { headers });

      // If the request image response it's ok create Blob with the imageResponse
      if (imageResponse.ok) {
        const imageBlob = await imageResponse.blob();

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(imageBlob);

        // Config file name
        const parts = type.split(".");
        const fileName = parts[0];
        downloadLink.download = `${fileName}.${parts[1] || "png"}`;

        // Add url at document body and simulate click to start the donwload
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        return;
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  return (
    <React.Fragment>
      <div className="p-[24px]">
        <Breadcrumbs
          breadcrumbs={["MNA", `${data?.name || ""}`]}
          icon={""}
          updateItem={() => navigate(`/update-mna/${id}`)}
        />
        {/* COMPANY INFORMATION */}
        <div className="  dark:bg-table-dark rounded-md mt-[24px]">
          <div className="flex flex-col md:flex-row gap-[16px] mb-[16px]">
            <div className="md:w-1/2 flex flex-col gap-[16px]">
              <div className=" overflow-x-auto">
                <div className=" min-w-full inline-block align-middle ">
                  <div className="border border-[#DCDBEE] rounded-md overflow-hidden bg-table-light dark:bg-table-dark pb-[8px]">
                    <table className="min-w-full  dark:bg-table-dark bg-table-light  ">
                      <caption className="text-left text-[18px] bg-table-light  dark:bg-table-dark font-semibold text-title pt-[16px] pb-2 px-[16px] ">
                        Company Information
                      </caption>
                      <thead className="bg-table-light dark:bg-table-dark dark:text-white">
                        <tr></tr>
                      </thead>
                      <tbody className="divide-[#DCDBEE] dark:bg-table-dark dark:text-white ">
                        <tr>
                          <td className="px-3.5 pb-2.5 pt-2  text-[#696868] dark:text-white   text-sm font-normal">
                            Ticker:
                          </td>
                          <td className="px-[16px] py-[10px]  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.tickerTarget}
                          </td>
                          <td className="px-3.5 py-[10px]  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.tickerAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] text-[#696868] dark:text-white   text-sm font-normal">
                            Name:
                          </td>
                          <td className="px-[16px]   text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.target}
                          </td>
                          <td className="px-3.5 text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.acquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] py-2  text-[#696868] dark:text-white   text-sm font-normal">
                            Sector:
                          </td>
                          <td className="px-3.5 py-[2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.sectorTarget}
                          </td>
                          <td className="px-3.5 py-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.sectorAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] py-2  text-[#696868] dark:text-white   text-sm font-normal">
                            Incorp:
                          </td>
                          <td className="px-3.5 py-[2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.incorpTarget}
                          </td>
                          <td className="px-3.5 py-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.incorpAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] py-2  text-[#696868] dark:text-white   text-sm font-normal">
                            HQ:
                          </td>
                          <td className="px-3.5 py-[2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.hqTarget}
                          </td>
                          <td className="px-3.5 py-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.hqAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] py-2  text-[#696868] dark:text-white   text-sm font-normal">
                            Geographic Exposure:
                          </td>
                          <td className="px-3.5 py-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.geoExposureTarget}
                          </td>
                          <td className="px-3.5 py-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.geoExposureAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] py-2  text-[#696868] dark:text-white   text-sm font-normal">
                            Financial Advisor:
                          </td>
                          <td className="px-3.5 py-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.financialAdvisorForTarget}
                          </td>
                          <td className="px-3.5 py-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.financialAdvisorForAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] py-2  text-[#696868] dark:text-white   text-sm font-normal">
                            Legal Counsel:
                          </td>
                          <td className="px-3.5 py-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.legalCounselForTarget}
                          </td>
                          <td className="px-3.5 py-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {data?.legalCounselForAcquirer}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* CONTACTS */}
              <div className={"flex w-full gap-[16px]"}>
                <div className="w-full">
                  <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                    <table className="w-full rounded-bl-lg rounded-br-lg bg-table-light dark:bg-table-dark">
                      <caption className="text-left text-[18px] text-title font-semibold pt-[16px] pb-2 px-[16px] bg-table-light dark:bg-table-dark">
                        Contacts
                      </caption>
                      <tbody className="">
                        <tr>
                          <td className="px-[16px]   text-[#000817] dark:text-white   text-sm font-medium text-left">
                            1:
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            2:
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            Info agent:
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] py-[16px]  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            Transfer agent: Broadridge
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* DATES */}
                <div className="w-full ">
                  <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                    <table className="w-full rounded-bl-lg rounded-br-lg bg-table-light dark:bg-table-dark">
                      <caption className="text-left text-[18px] text-title font-semibold pt-[16px] pb-2 px-[16px] bg-table-light dark:bg-table-dark">
                        Dates
                      </caption>
                      <tbody className="">
                        <tr>
                          <p className="px-[16px]  text-[#696868] dark:text-white   text-sm font-normal text-left">
                            Out Dates:
                          </p>
                          <td className="text-sm text-[#000817] font-medium ">
                            {data.outDates?.map((item: Item, index: number) => (
                              <div
                                key={`out-dates${index}`}
                                className={`flex justify-between items-start gap-4 ${
                                  data.outDates.length - 1 !== index && "mb-4"
                                }`}
                              >
                                <div className="w-1/2">
                                  {moment(item?.dateEvent).format("MM/DD/YYYY")}
                                </div>
                                <div className="w-1/2">{item?.comment}</div>
                              </div>
                            ))}
                          </td>
                        </tr>
                        <tr className="w-full">
                          <p className="px-[16px] pt-16  text-[#696868] dark:text-white   text-sm font-normal text-left">
                            Broker Closing <br /> Estimate Date:
                          </p>
                          <td className="text-sm text-[#000817] font-medium pt-16 ">
                            {data.brokerClosingDates?.map(
                              (item: Item, index: number) => (
                                <div
                                  key={`out-dates${index}`}
                                  className={`flex justify-between items-start gap-4 ${
                                    data.brokerClosingDates.length - 1 !==
                                      index && "mb-4"
                                  }`}
                                >
                                  <div className="w-1/2">
                                    {item?.dateEvent
                                      ? moment(item.dateEvent).format(
                                          "MM/DD/YYYY"
                                        )
                                      : ""}
                                  </div>
                                  <div className="w-1/2">{item?.comment}</div>
                                </div>
                              )
                            )}
                          </td>
                        </tr>
                        <tr>
                          <p className="px-[16px] pt-16   text-[#696868] dark:text-white   text-sm font-normal text-left">
                            Financing Date
                          </p>
                          <td className="text-sm text-[#000817] font-medium pt-16">
                            {data.financingDates?.length > 0 ? (
                              moment(data.financingDates[0]).format(
                                "MM/DD/YYYY"
                              )
                            ) : (
                              <div className="w-full text-center"></div>
                            )}

                            {data.financingDates?.map(
                              (item: Item, index: number) => (
                                <div
                                  key={`out-dates${index}`}
                                  className={`flex justify-between items-start ${
                                    data.financingDates.length - 1 !== index &&
                                    "mb-4"
                                  }`}
                                >
                                  <div className="w-1/2">
                                    {item?.dateEvent
                                      ? moment(item.dateEvent).format(
                                          "MM/DD/YYYY"
                                        )
                                      : ""}
                                  </div>
                                  <div className="w-1/2">{item?.comment}</div>
                                </div>
                              )
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] pt-16 pb-[16px]  text-[#696868] dark:text-white   text-sm font-normal text-left">
                            Required to Ligate
                          </td>
                          <td className=" pb-2 pt-16  w-full text-[#000817] dark:text-white   text-sm font-medium text-center">
                            {data?.requiredToLitigate ? "True" : "False"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* MNA BASICS */}
              <div className="md:w-full flex flex-col">
                <div className=" overflow-x-auto">
                  <div className=" min-w-full inline-block align-middle">
                    <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                      <table className="min-w-full dark:bg-table-dark bg-table-light">
                        <caption className="text-left text-[18px] px-4 text-title font-semibold pt-[16px] px-[16px]dark:bg-table-dark bg-table-light dark:bg-table-dark">
                          MNA Basics
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col" className="w-1/3"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody className="">
                          <tr>
                            <td className="px-[16px] pt-2  text-[#696868] dark:text-white   text-sm font-normal">
                              Date Announced:
                            </td>
                            <td className="px-[16px] pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                              {moment(data?.dateAnnounced).format("MM/DD/YYYY")}
                            </td>
                          </tr>
                          <tr>
                            <td className="px-[16px] pt-2  text-[#696868] dark:text-white   text-sm font-normal">
                              Terms:
                            </td>
                            <td className="px-[16px] pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                              {data?.terms}
                            </td>
                          </tr>

                          <tr>
                            <td className="px-[16px] pt-2  text-[#696868] dark:text-white   text-sm font-normal">
                              MNA value:
                            </td>
                            <td className="px-[16px] pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                              {data?.size}
                            </td>
                          </tr>
                          <tr>
                            <td className="px-[16px] pt-2  text-[#696868] dark:text-white   text-sm font-normal">
                              Premium:
                            </td>
                            <td className="px-[16px] pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                              {data?.premium}
                            </td>
                          </tr>
                          <tr>
                            <td className="px-[16px] pt-2  text-[#696868] dark:text-white   text-sm font-normal">
                              Closing Language:
                            </td>
                            <td className="px-[16px] pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left truncate max-w-[calc(100vw-80rem)]">
                              {data?.closingLanguage}
                            </td>
                          </tr>
                          <tr>
                            <td className="px-[16px] pt-2  text-[#696868] dark:text-white   text-sm font-normal">
                              Termination Fee Target:
                            </td>
                            <td className="px-[16px] pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                              {data?.terminationFeeTarget}
                            </td>
                          </tr>
                          <tr>
                            <td className="px-[16px] pt-2  text-[#696868] dark:text-white   text-sm font-normal">
                              Termination Fee Acquirer:
                            </td>
                            <td className="px-3.5 pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                              {data?.terminationFeeAcquirer}
                            </td>
                          </tr>
                          <tr>
                            <td className="px-[16px] pt-2 pb-[16px]  text-[#696868] dark:text-white   text-sm font-normal">
                              Required to Litigate Comment:
                            </td>
                            <td className="px-[16px] pt-2 pb-[16px]  text-[#000817] dark:text-white   text-sm font-medium text-left">
                              {data?.requiredToLitigateComment}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* WHAT MATTERS / COMPANY DESCRIPTION / BLOBERG INFO */}
            <div className="md:w-1/2 h-full">
              <div className="flex gap-3 mb-4">
                {tabsContent.map((tab, index) => (
                  <button
                    key={`${index}btnn`}
                    className={`min-w-[130px] h-[36px] px-2 ${
                      activeTab === index && "bg-[#E8F2FD] font-semibold"
                    } text-blue-primary border border-blue-primary  text-sm  rounded-md`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.header}
                  </button>
                ))}
              </div>
              <div className="border border-[#E4E3ED] rounded-md h-full bg-table-light dark:bg-table-dark pb-[2px] ">
                {tabsContent[activeTab].content}
              </div>
              <div className="md:w-1/2 flex flex-col mt-[16px]"></div>
              <div className="overflow-x-auto">
                {/* Holders Top 5 Holders */}
                <div className="min-w-full inline-block">
                  <div className="border border-[#DCDBEE] rounded-md overflow-hidden ">
                    <table className="min-w-full dark:bg-table-dark bg-table-light ">
                      <caption className="text-left text-[18px] font-semibold text-title pt-[16px] pb-2 px-[16px] bg-table-light dark:bg-table-dark">
                        Holders Top 5 Holders
                      </caption>
                      <tbody className="">
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[16px] text-[#000817] dark:text-white   text-sm font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-3.5 text-[#D70000] text-sm font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-3.5 text-[#007B1B] text-sm font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[16px]  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[16px]   text-[#000817] dark:text-white   text-sm font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-3.5 text-[#D70000] text-sm font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-3.5 text-[#007B1B] text-sm font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[16px]  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[16px]  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-3.5 text-[#D70000] text-sm font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-3.5 text-[#007B1B] text-sm font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[16px]  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[16px]  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-3.5 text-[#D70000] text-sm font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-3.5 text-[#007B1B] text-sm font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[16px]  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[16px] pb-[16px]  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-3.5 pb-[16px] text-[#D70000] text-sm font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-3.5 pb-[16px] text-[#007B1B] text-sm font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[16px] pb-[16px] text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {data?.links?.length > 0 && (
                <div className="overflow-x-auto mt-[16px]">
                  <div className="min-w-full inline-block align-middle">
                    {/* LINKS */}
                    <div className="border border-[#DCDBEE] rounded-md overflow-hidden pb-[16px] bg-table-light dark:bg-table-dark">
                      <table className="w-full rounded-bl-lg rounded-br-lg  bg-table-light dark:bg-table-dark">
                        <caption className="text-left text-[18px] font-semibold pt-[16px] pb-2 px-[16px]">
                          Links
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col" className="w-1/3"></th>
                            <th scope="col" className="w-2/3"></th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {data?.links?.map(
                            (item: ItemMetaData, index: number) => (
                              <tr key={`links-${index}`}>
                                <td className="px-[16px] pt-2  text-[#696868] dark:text-white   text-sm font-medium text-left">
                                  {item?.category}
                                </td>
                                <td className="px-[16px] pt-2  text-[#000817] dark:text-white   text-sm font-medium truncate max-w-[calc(100vw-72rem)]">
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={item.link}
                                  >
                                    {item?.link}
                                  </a>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-[calc(50%-8px)] mt-[16px] overflow-x-auto">
            <div className=" min-w-full inline-block align-middle">
              <div className="border border-[#DCDBEE] rounded-md overflow-hidden bg-table-light dark:bg-table-dark">
                <table className="min-w-full  dark:bg-table-dark">
                  <caption className="text-left text-title text-[18px] font-semibold  pt-[16px] pb-2 px-[16px]">
                    Features
                  </caption>
                  {data?.features?.length > 0 && (
                    <thead className="border-b-2 border-[#DCDBEE]">
                      <tr>
                        <th scope="col" className="text-left pt-2 px-3.5">
                          Name
                        </th>
                        <th scope="col" className="text-left py-2 px-3.5">
                          Value
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody className="">
                    {data?.features?.length > 0 ? (
                      data.features?.map((value: any, index: number) => (
                        <tr key={`features-${index}`}>
                          <td className="px-3.5 pt-2  text-[#696868] dark:text-white   text-sm font-normal">
                            {value.name}
                          </td>
                          <td className="px-3.5 py-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {value?.value}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="text-primary font-bold text-sm p-4 text-center">
                        No data found
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto mt-[16px]">
            <div className="min-w-full inline-block align-middle">
              {/* CONDITIONS */}
              <div className="pt-[16px] px-[16px] border border-[#DCDBEE] bg-table-light pb-4 rounded-md ">
                <h4 className="text-xs xs:text-xs text-title sm:text-sm md:text-sm lg:text-lg font-semibold ">
                  Conditions
                </h4>
                <table className="min-w-full dark:bg-table-dark bg-table-light border border-[#DCDBEE] rounded-lg overflow-hidden mt-4 ">
                  <thead className="bg-[#0E7DBB14] ">
                    <tr>
                      <th className="px-4 py-4 text-center">Condition</th>
                      <th className="px-4 py-4 text-start">Name</th>
                      <th className="px-4 py-4 text-start">
                        Expected/Confirmed
                      </th>
                      <th className="px-4 py-4 text-start">Filed</th>
                      <th className="px-4 py-4 text-start">Commentary</th>
                      <th className="px-4 py-4 text-end">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.conditions?.map(
                      (item: IConditions, index: number) => (
                        <tr
                          key={`${index}conditions`}
                          className="border-b border-[#DCDBEE]"
                        >
                          <td className="px-4 py-7 text-center">{index + 1}</td>
                          <td className="px-4 py-7 text-start">
                            {item?.category?.name}
                          </td>
                          <td className={`px-4 py-7 text-start`}>
                            {item?.expectedConfirmed === null
                              ? null
                              : moment(
                                  formatAndValidateDate(item?.expectedConfirmed)
                                ).format("MM/DD/YYYY")}
                          </td>
                          <td className="px-4 py-7 text-start">
                            {item?.filed === null
                              ? null
                              : moment(
                                  formatAndValidateDate(item?.filed)
                                ).format("MM/DD/YYYY")}
                          </td>
                          <td className="px-4 py-7 text-start">
                            {item?.commentary}
                          </td>
                          <td className="px-4 py-7 text-end">
                            {item?.statusId}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto mt-[16px]">
            <div className="min-w-full inline-block align-middle bg-table-light dark:bg-table-dark">
              {/* NOTES */}
              <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                <div className="min-w-full rounded-bl-lg rounded-br-lg">
                  <div className="text-left font-semibold  pt-[16px] pb-2 px-[16px]">
                    <div className="flex items-center justify-between">
                      <div className="text-xs xs:text-xs text-title sm:text-sm md:text-sm lg:text-lg">
                        Notes
                      </div>
                    </div>
                  </div>
                  <div className="p-[16px]">
                    <Table
                      fetchData={fetchMnaNoteList}
                      columns={columnsInstance.NotesListDetail()}
                      additionalDataKey="notes"
                      openModal={openModal}
                    />
                    {}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto mt-[16px] ">
            <div className="min-w-full inline-block align-middle">
              <div className="border border-[#DCDBEE] rounded-md overflow-hidden bg-table-light dark:bg-table-dark">
                <table className="min-w-full rounded-bl-lg rounded-br-lg">
                  <caption className="text-left text-title text-[18px] font-semibold  pt-[16px] pb-2 px-[16px]">
                    Attached files
                  </caption>
                  <thead className="">
                    <tr></tr>
                  </thead>
                  <tbody className="">
                    {data?.files?.length > 0 ? (
                      data?.files?.map((item: any, index: number) => (
                        <tr key={`files-${index}`}>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            <button
                              onClick={() =>
                                handleFileDownload(item.filename, item.fileId)
                              }
                            >
                              {item.filename}
                            </button>
                          </td>
                          <td className="px-[16px] pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {getFileSize(item.size)}
                          </td>
                          <td className="px-[16px] pt-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                            {moment(item?.dateModified).format("MM/DD/YYYY")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td
                          colSpan={3}
                          className="text-primary font-bold text-sm p-4"
                        >
                          None
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
      {isModalOpen && content && (
        <div
          className={`fixed top-0 left-0 z-30 flex h-full min-h-screen w-full items-center justify-center px-4 py-5  ${
            isModalOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div
            className="absolute inset-0 transition-opacity bg-[#222222]"
            onClick={closeModal}
          ></div>
          <div className="pointer-events-auto relative flex h-[680px] w-[1440px] flex-col overflow-hidden rounded-md border-none bg-[#EAE5F7] bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div className="flex justify-end p-5">
              <img
                src={Close}
                alt="clsoe"
                onClick={() => closeModal()}
                className="rounded-full p-2 border border-[#E6E6F9] bg-b-seconday-light cursor-pointer"
              />
            </div>
            <h2 className="px-[50px] pt-[18px] pb-5 text-[#fff] bg-primary-default dark:bg-black font-semibold text-lg">
              Description
            </h2>
            {/*Modal body*/}
            <div className="relative overflow-y-auto py-4 px-[50px]">
              <ul className="list-disc">
                <li
                  className=" text-[#393C51]  dark:text-white  text-sm font-normal leading-5 capitalize mb-3"
                  dangerouslySetInnerHTML={{
                    __html: content || "",
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MnaDetails;
