import React, { useEffect, useRef, useState } from 'react';
import Search from "../../Assets/svg/search.svg";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchFiltersEnum, SearchRestControllerApi } from "../../openapi";
import { apiConfig } from "../ConfigurationApi/Configuration";
import { ListSearch } from "./components";

// ICONS

const SearchDialog = () => {
    const [showHistory, setShowHistory] = useState(false);
    const [searchHistory, setSearchHistory] = useState<string[]>([]);
    const [searchResults, setSearchResults] = useState<any>(null);
    const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [query, setQuery] = useState("");
    const [filters, setFilters] = useState<Set<SearchFiltersEnum>>(new Set([
        SearchFiltersEnum.All,
    ]));
    const [dateFilter, setDateFilter] = useState<string>('');

    const [currentOperator, setCurrentOperator] = useState("");
    const searchContainerRef = useRef<any>(null);

    const useDebounce = (value: any, delay: any) => {
        const [debouncedValue, setDebouncedValue] = useState(value);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [value, delay]);

        return debouncedValue;
    };

    const debouncedQuery = useDebounce(query, 300);
    const fetchSearchResults = async (searchQuery: string) => {
        setLoading(true);
        try {
            const api = new SearchRestControllerApi(apiConfig());

            const page = {
                "page": 0,
                "size": 5,
                "sort": [
                    "asc"
                ]
            }
            const search: any = {
                "rootCriteria": {
                    "type": "AND",
                    "criteria": [
                        {
                            "criteria": [
                                {
                                    "field": "ticker",
                                    "value": searchQuery,
                                    "matchType": "EXACT"
                                },
                                {
                                    "field": "content",
                                    "value": searchQuery,
                                    "matchType": "CONTAINS"
                                }
                            ],
                            "type": "OR"
                        }
                    ]
                }
            }
            if (dateFilter) {
                search.rootCriteria.criteria.push({
                    "field": "dateCreated",
                    "value": dateFilter,
                    "matchType": "GREATER_THAN"
                })
            }

            const response: any = await api.search(filters, page, search);
            return response.data;
        } catch (error) {
            console.error("Error fetching search results", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = async () => {
        setLoading(true);
        try {
            const results = await fetchSearchResults(debouncedQuery);
            setSearchResults(results);
            // setShowHistory(false);
            setSearchHistory((prevHistory: any) => [
                debouncedQuery,
                ...prevHistory.filter((item: any) => item !== debouncedQuery),
            ]);
        } catch (error) {
            console.error("Error fetching search results:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleAutocomplete = (suggestion: any) => {
        setQuery(`${currentOperator}:${suggestion}`);
        setAutocompleteSuggestions([]);
    };

    const handleRemoveHistoryItem = (itemToRemove: any) => {
        setSearchHistory((prevHistory: any) =>
            prevHistory.filter((item: any) => item !== itemToRemove)
        );
        setShowHistory(true);
    };

    const handleClearQuery = () => {
        setQuery('');
        setAutocompleteSuggestions([]);
    };

    useEffect(() => {
        if (debouncedQuery) {
            handleSearch();
        } else {
            setSearchResults(null);
        }
    }, [debouncedQuery, filters, dateFilter]);

    return (
        <div className="flex mr-4 w-full">
            <div
                ref={searchContainerRef}
                className={`${
                    showHistory || searchResults
                        ? "rounded-md"
                        : "rounded-md"
                } relative hidden lg:block bg-white dark:bg-b-secondary-dark w-full md:max-w-full`}
            >
                {/* INPUT SEARCH */}
                <div className={`relative flex items-center`}>
                    <img
                        src={Search}
                        alt="search"
                        className="absolute top-3 left-3 brightness-0 "
                    />
                    <input
                        type="text"
                        placeholder="Search"
                        value={query}
                        onChange={(e) => {
                            const value = e.target.value;
                            setQuery(value);
                            const match = value.match(/(ticker|company):/);
                            setCurrentOperator(match ? match[1] : "");
                        }}
                        onClick={() => setShowHistory(true)}
                        onFocus={() => setShowHistory(true)}
                        onBlur={() =>
                            setTimeout(() => {
                                // Hide history and autocomplete suggestions only if click is outside
                                if (
                                    !searchContainerRef?.current?.contains(
                                        document.activeElement
                                    )
                                ) {
                                    setAutocompleteSuggestions([]);
                                }
                            }, 200)
                        } // Delay to allow click
                        onKeyDown={(e) => {
                            if (e.key === "Tab" && autocompleteSuggestions.length > 0) {
                                e.preventDefault();
                                handleAutocomplete(autocompleteSuggestions[0]);
                            }
                        }}
                        className={`text-sm font-normal w-full bg-[#F6F8FA] pl-12 py-1 h-10 pr-12 outline-none placeholder:text-black dark:placeholder:text-dark text-black dark:text-dark`}
                    />
                    {query && (
                     <FontAwesomeIcon
                     icon={faCircleXmark}
                         className="absolute top-3 right-3 cursor-pointer brightness-0"
                         onClick={handleClearQuery}
                     />
                    )}
                </div>
                {/* SHOW DATA */}
                <ListSearch
                    searchResults={searchResults}
                    showHistory={showHistory}
                    loading={loading}
                    query={query}
                    searchHistory={searchHistory}
                    setQuery={setQuery}
                    handleSearch={handleSearch}
                    handleRemoveHistoryItem={handleRemoveHistoryItem}
                    autocompleteSuggestions={autocompleteSuggestions}
                    handleAutocomplete={handleAutocomplete}
                    debouncedQuery={debouncedQuery}
                    setSearchResults={setSearchResults}
                    setFilters={setFilters}
                    filters={filters}
                    setDateFilter={setDateFilter}
                    dateFilter={dateFilter}
                    setShowHistory={setShowHistory}
                />
            </div>
        </div>
    );
};

export default SearchDialog;
