import React, { useState, useRef, useEffect } from "react";

interface DropdownProps {
  options?: any[];
  selectedOption?: any;
  onOptionSelect?: (option: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  selectedOption,
  onOptionSelect,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (option: string) => {
    if (onOptionSelect) {
      // Check if onOptionSelect is defined before calling it
      onOptionSelect(option);
    }
    setIsOpen(false); // Close the dropdown when an item is clicked
  };

  return (
    <div ref={dropdownRef} className="relative inline-block text-left">
      <button
        type="button"
        onClick={toggleMenu}
        className="inline-flex justify-start items-center w-full gap-x-1.5 rounded-md bg-primary-light  px-3 py-2 text-sm font-semibold text-black dark:text-white shadow-sm ring-1 ring-inset ring-primary-dark hover:bg-gray-50 dark:ring-primary-light dark:bg-primary-dark"
        id="menu-button"
        aria-expanded={isOpen ? "true" : "false"}
        aria-haspopup="true"
      >
        {selectedOption.label}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 22 20"
          width="16"
          height="16"
        >
          <path
            fill="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M20 0H0l8 9.46V16l4 2V9.46z"
            transform="translate(1 1)"
          ></path>
        </svg>
      </button>

      {isOpen && (
        <div
          className="absolute right-0 z-10 w-full mt-2 origin-top-right rounded-md  bg-primary-light dark:bg-secondary-dark shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:ring-primary-light dark:bg-primary-dark"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          <div className="py-1" role="none">
            {options?.map((option) => (
              <button
                key={option.label}
                className={`text-black w-full dark:text-white  block  px-4 py-2 text-sm ${
                  selectedOption.label === option.label
                    ? "bg-button-light dark:bg-button-dark"
                    : ""
                }`}
                role="menuitem"
                tabIndex={-1}
                onClick={() => handleItemClick(option)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
