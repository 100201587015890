// Components
import DeleteTooltip from "../../Delete-Tooltip";
import InputField from "../../Common-inputs";
// Types
import { StepBase } from "..";

interface StepSevenProps extends StepBase {
  conditionCategoryList: any;
  conditionStatusList: any;
  Minus: string;
  Plus: string;
}

export default function StepSeven(props: StepSevenProps) {
  const {
    association,
    conditionCategoryList,
    conditionStatusList,
    deleteID,
    deleteModal,
    formik,
    handleRemoveAssociation,
    Minus,
    Plus,
    serverError,
    setAssociation,
    setDeleteID,
    setDeleteModal,
  } = props;

  const handleAddConditions = () => {
    formik.setValues({
      ...formik.values,
      conditions: [
        ...formik.values.conditions,
        {
          statusId: "",
          conditionCategoryId: "",
          commentary: "",
          filed: null,
          expectedConfirmed: null,
        },
      ],
    });
  };

  return (
    <div className="border border-[#E4E3ED] rounded-lg dark:bg-table-dark bg-table-light ">
      <div className="px-[23px] py-[30px]">
        <div>
          {formik.values.conditions?.map((condition: any, index: number) => (
            <div key={index} className={` ${index === formik.values.conditions?.length - 1 ? " " : "mb-[30px]"}`}>
              <div className="flex flex-col gap-[30px] xl:flex-row items-center">
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Condition"
                    id={`conditions[${index}].conditionCategoryId`}
                    name={`conditions[${index}].conditionCategoryId`}
                    type="select"
                    placeholder="Select"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    optionList={conditionCategoryList}
                    labelKey="name"
                    valueKey="id"
                    isDynamic={true}
                    value={condition.conditionCategoryId}
                    showAdditionalComment
                  />
                </div>
                {Number(condition.conditionCategoryId) === 15 && (
                  <div className="w-full xl:w-1/3">
                    <InputField
                      label="Condition Comment"
                      id={`conditions[${index}].categoryOtherComment`}
                      name={`conditions[${index}].categoryOtherComment`}
                      type="text"
                      placeholder="Comment"
                      serverError={serverError || ""}
                      formik={formik}
                      showIcon
                      isDynamic={true}
                      value={condition.categoryOtherComment}
                    />
                  </div>
                )}
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Filed"
                    id={`conditions[${index}].filed`}
                    name={`conditions[${index}].filed`}
                    type="date"
                    placeholder="Select date"
                    formik={formik}
                    serverError={serverError || ""}
                    showIcon
                    isDynamic={true}
                    value={condition.filed}
                  />
                </div>
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Expected /Confirmed"
                    id={`conditions[${index}].expectedConfirmed`}
                    name={`conditions[${index}].expectedConfirmed`}
                    type="date"
                    last={true}
                    placeholder="Select date"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    isDynamic={true}
                    value={condition.expectedConfirmed}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-[30px] xl:flex-row items-center mt-[30px]">
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Status"
                    id={`conditions[${index}].statusId`}
                    name={`conditions[${index}].statusId`}
                    type="select"
                    placeholder="Select"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    optionList={conditionStatusList}
                    labelKey="status"
                    valueKey="id"
                    isDynamic={true}
                    value={condition.statusId}
                  />
                </div>
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Commentary"
                    id={`conditions[${index}].commentary`}
                    name={`conditions[${index}].commentary`}
                    type="text"
                    placeholder="Enter here..."
                    formik={formik}
                    serverError={serverError || ""}
                    showIcon
                    isDynamic={true}
                    value={condition?.commentary}
                  />
                </div>
                <div className="w-full xl:w-1/3 relative">
                  <button
                    onClick={() => {
                      setDeleteID(index);
                      setAssociation("conditions");
                      setDeleteModal(true);
                    }}
                    type="button"
                    className="text-secondary text-base font-medium flex items-center gap-[6px]"
                  >
                    <img src={Minus} alt="minus" /> Remove
                  </button>
                  {deleteID === index && association === "conditions" && (
                    <DeleteTooltip
                      className="bottom-10"
                      tooltip={deleteModal}
                      setTooltip={setDeleteModal}
                      onDeleteItem={handleRemoveAssociation}
                      top
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="flex justify-center items-center mt-[30px]">
            <button
              type="button"
              onClick={handleAddConditions}
              className="text-secondary text-lg py-4  font-bold flex items-center justify-center  border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:text-white"
            >
              <img src={Plus} alt="plus" className="dark:brightness-200" /> 
              Add new
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
