// React
import React, { useState } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Components
import { Columns } from "../Components/Columns/Columns";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Table from "../Components/Tables/Note-Table";
// openAPI
import { getApiData } from "../Controller/getApiDataConfiguration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
// Images
import Close from "../Assets/svg/clsoe.svg";
import NoteIcon from "../Assets/svg/blue-note.svg";

const NotesList = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<any>('datePublished');
  const [content, setContent] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>( { label: "Date Published", value: "datePublished" });

  const options = [
    { label: "Date Published", value: "datePublished" },
    { label: "Date Created", value: "dateCreated" },
    { label: "Date Modified", value: "dateModified" },
  ];

  const handleItemClick = (option: any) => {
    setSelectedOption(option);
    setSort(option.value)
  };

  const columnsInstance = new Columns();

  const fetchNoteList = async (pageIndex: number, pageSize: number) => {
    try {
      const sortBy = [
        {
            "id": sort,
            "desc": true
        }
    ] as any
      const getfetchNoteList = new getApiData();
      return getfetchNoteList.getNoteRestControllerApi(search, pageIndex, pageSize, sortBy);
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  const openModal = (content: string) => {
    setContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <div className="p-[30px]">
        <Breadcrumbs
          breadcrumbs={["All Notes"]}
          icon={NoteIcon}
          addItem={() => navigate("/notes/add")}
          onFilter={(value) => setSearch(value)}
          isSort={true}
          options={options}
          selectedOption={selectedOption}
          handleItemClick={handleItemClick}
        />
        <div className="mt-8">
          <Table
            columns={columnsInstance.NotesList(navigate)}
            fetchData={fetchNoteList}
            additionalDataKey="content"
            openModal={openModal}
            loading={loading}
          />
        </div>
      </div>
      {isModalOpen && content && (
        <div
          className={`fixed top-0 left-0 z-30 flex h-full min-h-screen w-full items-center justify-center px-4 py-5  ${isModalOpen ? "translate-x-0" : "translate-x-full"
            }`}
        >
          <div className="absolute inset-0 transition-opacity bg-[#222222]" onClick={closeModal}></div>
          <div className="pointer-events-auto relative flex h-[680px] w-[1440px] flex-col overflow-hidden rounded-md border-none bg-[#EAE5F7] bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div className="flex justify-end p-5">
              <img
                src={Close}
                alt="clsoe"
                onClick={() => closeModal()}
                className="rounded-full p-2 border border-[#E6E6F9] bg-b-seconday-light cursor-pointer"
              />
            </div>
            <h2 className="px-[50px] pt-[18px] pb-5 text-[#fff] bg-primary-default dark:bg-black font-semibold text-lg">
              Description
            </h2>
            {/*Modal body*/}
            <div className="relative overflow-y-auto py-4 px-[50px]">
              <ul className="list-disc">
                <li
                  className=" text-[#393C51]  dark:text-white  text-sm font-normal leading-5 capitalize mb-3"
                  dangerouslySetInnerHTML={{
                    __html: content || "",
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default NotesList;
