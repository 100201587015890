import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Configuration, PasswordResetControllerApi } from "../../openapi";
import Loader from "../../Components/Loader";

const ValidateToken: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token: any = queryParams.get("token");
  const [valid, setValid] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const validateToken = async () => {
      setLoading(true);
      if (!token) {
        setValid(false);
        return;
      }

      try {
        const configuration = new Configuration({
          basePath: process.env.REACT_APP_API_URL,
        });

        const api = new PasswordResetControllerApi(configuration);
        await api.validateToken(token);
        setValid(true);
      } catch (error) {
        setValid(false);
      }
      setLoading(false);
    };
    validateToken();
  }, [token]);

  const handleOkClick = () => {
    if (valid) {
      navigate(`/reset-password?token=${encodeURIComponent(token)}`);
    } else {
      navigate("/forgot-password");
    }
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {valid !== null && (
        <div className="fixed inset-0 bg-primary-dark bg-opacity-75 transition-opacity"></div>
      )}
      {valid !== null && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <div className="relative bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 w-[400px]">
              <div className="bg-white rounded-xl px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start sm:justify-center">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <div className="mt-2">
                      {valid === null && <p className="">Loading...</p>}
                      {valid === false && (
                        <div className="text-center">
                          <div className="bg-danger rounded-full p-4 mb-4 mx-auto w-16 h-16 flex items-center justify-center">
                            <svg
                              className="w-8 h-8 text-white"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              ></path>
                            </svg>
                          </div>
                          <h2 className="text-lg font-semibold mb-2 text-red-600">
                            Verification Failed
                          </h2>
                          <p className="text-gray-600 mb-4">
                            Invalid or expired email
                          </p>
                          <button
                            className="bg-danger text-white px-4 py-2 rounded hover:bg-red-600"
                            onClick={handleOkClick}
                          >
                            Ok
                          </button>
                        </div>
                      )}
                      {valid === true && (
                        <div className="text-center">
                          <div className="bg-primary-default rounded-full p-4 mb-4 mx-auto w-16 h-16 flex items-center justify-center">
                            <svg
                              className="w-8 h-8 text-white"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              ></path>
                            </svg>
                          </div>
                          <h2 className="text-lg font-semibold mb-2">
                            Verified!
                          </h2>
                          <p className="text-gray-600 mb-4">
                            You have successfully verified your account.
                          </p>
                          <button
                            className="bg-primary-default text-white px-4 py-2 rounded hover:bg-blue-600"
                            onClick={handleOkClick}
                          >
                            Log in
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {valid === null && <Loader loading={loading} />}
    </div>
  );
};

export default ValidateToken;
