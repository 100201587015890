// React
import React, { useEffect, useRef, useState } from "react";
// Thrid party
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// Components
import Alerts from "../Components/Alerts";
import { RootState } from "../store/store";
// There
import { useTheme } from "../App";
// Data
import { navbarData } from "../data/navbarData";
// Images
import arrow from "../Assets/images/arrow.png";
import BurguerMenu from "../Assets/svg/burguer-menu.svg";
import DarkLogo from "../Assets/images/logo_dark.png";
import LightLogo from "../Assets/images/logo_light.png";
// Icons
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserMenuDropdown from "../Components/Profile-Menu";
import SearchDialog from "../Components/SearchDialog/SearchDialog";


interface Data {
  path: string;
  icon: string;
  active: string;
  name: string;
}

interface Mna {
  id: number;
  name: string;
  categoryName: string;
}

interface Note {
  id: number;
  title: string;
  content: string;
}

const MenuItem: React.FC<Data> = ({ path, icon, active, name }) => {
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string }>();

  const isActive =
    pathname === path ||
    ((pathname === "/notes/add" || pathname === `/update-note/${id}`) &&
      path === "/notes") ||
    ((pathname === "/add-mna" ||
      pathname === `/add-mna/${id}` ||
      pathname === `/update-mna/${id}` ||
      pathname === `/mna/${id}`) &&
      path === "/mnas") ||
    ((pathname === "/add-fundamental" ||
      pathname === `/update-fundamental/${id}` ||
      pathname === `/fundamental/${id}`) &&
      path === "/fundamental");

  const getBorderColor = () => {
    return isActive ? "border-[#F3797E]" : "border-transparent";
  };

  const getTextClass = () => {
    return isActive ? "text-secondary" : "";
  };

  return (
    <li className={`border-b-[3px] py-[24px] ${getBorderColor()}`}>
      <Link to={path}>
        <div className="flex gap-[6px] items-center p-[10px]">
          <div>
            <img src={isActive ? active : icon} alt="active" />
          </div>
          <div>
            <p
              className={`text-[#393C51}] text-base font-normal ${getTextClass()}`}
            >
              {name}
            </p>
          </div>
        </div>
      </Link>
    </li>
  );
};


const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const role = localStorage.getItem("role");
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navigateDashboard = () => {
    navigate("/dashboard");
  };

  const closeMenu = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeMenu);
    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, []);

  const filteredNavbarData =
  role === "ROLE_ADMIN"
    ? navbarData
    : navbarData.filter((item) => item.pathname !== "error" && item.pathname !== "data-migration");



  return (
    <header className="sticky top-0 z-10 bg-[#FFFFFF] dark:bg-black">
      <div
        className="flex flex-wrap items-center justify-between mx-auto px-[30px]"
        style={{ boxShadow: "0px 8px 28px 0px rgba(72, 89, 102, 0.10)" }}
      >
        <div className="flex items-center gap-8">
          {/* App Logo */}
          <div className="my-2 pr-[52px] border-e	dark:border-white">
            <img
              src={theme.theme === "light" ? LightLogo : DarkLogo}
              alt="Logo"
              className="cursor-pointer"
              onClick={navigateDashboard}
            />
          </div>
          {/* Sidebar */}
          <div ref={menuRef}>
            <button type="button" className="" onClick={toggleMenu}>
              <img
                src={BurguerMenu}
                alt="butguer menu"
                className="h-5 dark:invert"
              />
            </button>
            <div
              className={`${
                isOpen ? "translate-x-0" : "-translate-x-full"
              } fixed top-[79px] py-4 flex flex-col justify-between left-0 w-[284px] h-full transition-all duration-500 transform bg-[rgb(14,111,166)] shadow-lg peer-checked:translate-x-0 
              dark:bg-[#02324D]`}
            >
              <div>
                <div className="space-y-1 pb-3 pt-2">
                  {filteredNavbarData.map((item, index) => (
                    <Link
                      key={`navbar-${item.title}-${index}`}
                      to={`/${item.pathname}`}
                      onClick={() => setIsOpen(false)}
                      className={`${
                        pathname === `/${item.pathname}`
                          ? "text-[#F2F1FD] bg-[rgb(9,89,142)] dark:text-[#F2F1FD] dark:bg-[rgb(9,89,142)]"
                          : "text-[#F2F1FD]"
                      }   block px-8 py-2 2xl:py-4 text-base font-medium hover:bg-[#09598E] transition-all duration-500 ease-out`}
                    >
                      <div className="flex gap-[6px] items-center">
                        <div>
                          <img src={item.lightIcon} alt="active" className="" />
                        </div>
                        <div>
                          <p
                            className={`text-[#393C51}] text-base font-normal`}
                          >
                            {item.title}
                          </p>
                        </div>
                        <div>
                          <img
                            className="w-4 rotate-90 absolute right-2 mt-[-8px]"
                            src={arrow}
                            alt=""
                          />
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center md:order-2 w-4/6 justify-end">
          <SearchDialog />
          <div className="mr-3 cursor-pointer" onClick={theme.toggleTheme}>
            {theme.theme === "dark" ? (
                <FontAwesomeIcon
                    icon={faToggleOn}
                    className="text-[#808080] text-[20px]"
                />
            ) : (
                <FontAwesomeIcon icon={faToggleOff} className="text-[20px]"/>
            )}
          </div>
          <div className="mr-6">
            <Alerts className="w-[30px] h-[30px]" isIcon/>
          </div>
          <div className="2xl:block">
            <UserMenuDropdown/>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
