// React
import React, { useCallback, useState } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Components
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import { Columns } from "../Components/Columns/Columns";
import Table from "../Components/Tables/Mna-Table";
// openAPI
import { getApiData } from "../Controller/getApiDataConfiguration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
// Types
import { Sort } from "../interfaces/pages/variedInterfaces";
// Images
import mnaIcon from "../Assets/svg/blue-mna.svg";
import loader from "../Components/Loader";

const MnaList = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const fetchMnaList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      setLoading(true);
      try {
        const api = new getApiData();
        return api.getMnaRestControllerApi(search, pageIndex, pageSize, sortBy);
      } catch (error) {
        expiredTokenValidation(error);
      } finally {
        setLoading(false);
      }
    },
    [search]
  );

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
      <div className={`p-[30px] flex flex-col gap-[29px]`}>
        <Breadcrumbs
          breadcrumbs={["MNAs"]}
          icon={mnaIcon}
          addItem={() => navigate("/add-mna")}
          onFilter={(value) => setSearch(value)}
        />
      
          <Table
            columns={columnsInstance.MnaList(navigate)}
            fetchData={fetchMnaList}
            search={search}
            loading={loading}
          />
       
      </div>
    </React.Fragment>
  );
};

export default MnaList;
