// React
import { useEffect, useState } from "react";
// Third party
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
// OpenAPI
import {
  FileRestControllerApi,
  NoteResponse,
  NoteRestControllerApi,
} from "../openapi";
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { ResponseFile } from "../interfaces/pages/variedInterfaces";
import { getFileSize } from "../utils";

export default function NoteDetails() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [notesData, setNotesData] = useState<any>();
  const [domains, setDomains] = useState<
    { name: string; id: number; typeId: number }[]
  >([]);
  const [tags, setTags] = useState<string[]>([]);
  const navigate = useNavigate();
  const fetchNoteData = async () => {
    setLoading(true);
    try {
      const api = new NoteRestControllerApi(apiConfig());

      const response = await api.getNoteById(String(id));
      if (response.data) {
        const data: NoteResponse = response.data;
        setNotesData(data);
        setDomains(
          (data as any)?.domains?.map((domain: any) => {
            return {
              name: domain?.name,
              id: domain?.entityId,
              typeId: domain.typeId,
            };
          })
        );
        setTags(
          data?.tags?.map((tag) => {
            return tag.tag;
          }) || []
        );
      }
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNoteData();
  }, [id]);

  const handleFileDownload = async (id: string, type: string) => {
    try {
      // Set Authorization header
      const headers: {} = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const api = new FileRestControllerApi(apiConfig());
      console.log(api, type);
      const response = await api.downloadFile(id);
      console.log(id, response);
      const imageUrl = (response as ResponseFile).config.url;

      // Getting the image url
      const imageResponse = await fetch(imageUrl, { headers });

      // If the request image response it's ok create Blob with the imageResponse
      if (imageResponse.ok) {
        const imageBlob = await imageResponse.blob();

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(imageBlob);

        // Config file name
        const parts = type.split(".");
        const fileName = parts[0];
        downloadLink.download = `${fileName}.${parts[1] || "png"}`;

        // Add url at document body and simulate click to start the donwload
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        return;
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div>
      <div className="w-full bg-gray-200 dark:bg-gray-900">
        <div className="container-fluid mx-auto flex items-start justify-center">
          <div className="w-full m-6">
            <div className="mx-auto w-full bg-table-sub-light dark:bg-gray-800 shadow dark:bg-button-dark">
              <div className="bg-primary-default justify-between p-3 flex flex-col lg:flex-row items-start lg:items-center dark:bg-sub-table-light dark:text-white">
                <div className="flex flex-col mt-3 lg:mt-0 md:flex-row items-start md:items-center">
                  {domains?.map((domain: any, index: number) => (
                    <h2
                      key={index}
                      className="text-md lg:text-md text-primary-dark dark:text-primary-light font-bold"
                    >
                      {domain.name}
                      {`${index !== domains.length - 1 ? "," : ""}`}
                    </h2>
                  ))}
                </div>
                <div className="flex gap-2 flex-col mt-3 lg:mt-0 md:flex-row  items-start md:items-center">
                  {tags?.map((tag: any, index: number) => (
                    <div
                      key={index}
                      className="bg-[#E5E7EB] text-primary-dark font-bold dark:text-primary-light dark:bg-primary-dark rounded text-sm leading-3 py-2 px-3"
                    >
                      {tag}
                    </div>
                  ))}
                </div>
              </div>
              <div className="justify-between p-2 flex flex-col lg:flex-row items-start lg:items-center px-5">
                <div className="w-full lg:w-1/2 pr-0 lg:pr-48">
                  <h1 className="cursor-pointer text-primary-dark dark:text-primary-light font-bold text-xl">
                    {notesData?.title}
                  </h1>
                  <p className="mt-2 text-lg text-primary-dark dark:text-primary-light font-bold">
                    {notesData?.sourceType}
                  </p>
                </div>
                <div className="w-full lg:w-1/2">
                  <div className="flex justify-end">
                    <span className="group relative cursor-pointer">
                      <div className="absolute bottom-10 lg:-bottom-9 lg:right-3  -translate-x-[50%] hidden group-hover:block w-[350px]">
                        <div className="bg-[#fff] text-[#393C51]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  px-[14px] pt-[12px] pb-[17px] rounded-[10px] border border-[#4185EE] ">
                          <ul className="max-w-md space-y-1 text-dark list-disc list-inside dark:text-white">
                            <li>
                              Date Published:{" "}
                              {moment(notesData?.datePublished).format(
                                "MM/DD/yyyy hh:mm a"
                              )}
                            </li>
                            <li>
                              Date Created:{" "}
                              {moment(notesData?.dateCreated).format(
                                "MM/DD/yyyy hh:mm a"
                              )}{" "}
                              {notesData?.createdBy?.username}
                            </li>
                            <li>
                              Date Modified:{" "}
                              {moment(notesData?.dateModified).format(
                                "MM/DD/yyyy hh:mm a"
                              )}{" "}
                              {notesData?.lastModifiedBy?.username}
                            </li>
                          </ul>
                          <div className=" bottom-0 right-[39px]  -translate-x-1/2 translate-y-1/2 absolute  lg:bottom-8 lg:right-0  rotate-[135deg] transform   lg:translate-x-1/2  lg:-translate-y-1/2 lg:rotate-45 w-4 h-4 bg-white border-r border-t border-[#4185EE] dark:bg-primary-dark"></div>{" "}
                        </div>
                      </div>
                      <h2 className="mt-2 text-primary-dark dark:text-primary-light font-bold text-md leading-6 lg:mt-0 lg:text-end">
                        {moment(notesData?.datePublished).format(
                          "MM/DD/yyyy hh:mm a"
                        )}
                      </h2>
                    </span>
                  </div>

                  <p className="mt-2 text-primary-dark dark:text-primary-light text-md leading-5 lg:text-end font-bold">
                    {notesData?.sourceDetail && notesData?.author1
                      ? `${notesData?.sourceDetail} - ${notesData?.author1}`
                      : notesData?.author1
                      ? notesData?.author1
                      : notesData?.sourceDetail
                      ? notesData?.sourceDetail
                      : ""}
                  </p>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row px-5 pb-8 gap-3 mt-2">
                <div className="flex flex-col lg:flex-row w-full lg:w-11/12 items-start lg:items-center mb-8 lg:mb-0">
                  <div
                    className="ql-container ql-snow"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: notesData?.content || "",
                      }}
                    ></div>
                  </div>
                </div>
                <div className="flex items-end w-full lg:w-1/12 justify-end lg:justify-end">
                  <button
                    className="text-secondary font-semibold text-base capitalize underline float-right"
                    onClick={() => navigate(`/notes/${notesData?.id}/edit`)}
                  >
                    <div className="rounded-full  p-3 border border-[#E6E6F9] bg-button-light dark:bg-button-dark cursor-pointer">
                      <svg
                        className="dark:brightness-[3.5] brightness-0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_1000_6286)">
                          <path
                            d="M9.88081 2.68738L1.07744 11.4914C1.03311 11.5359 1.00152 11.5914 0.985954 11.6522L0.010151 15.5688C-0.00420054 15.6269 -0.00332103 15.6877 0.0127046 15.7454C0.0287303 15.8031 0.0593605 15.8557 0.101637 15.8981C0.166572 15.9628 0.254492 15.9992 0.346172 15.9992C0.374454 15.9992 0.402627 15.9957 0.430055 15.9888L4.34662 15.0129C4.40752 14.9976 4.46311 14.966 4.50743 14.9216L13.3116 6.11812L9.88081 2.68738ZM15.4926 1.48695L14.5126 0.507024C13.8577 -0.14793 12.7162 -0.14728 12.0619 0.507024L10.8616 1.70745L14.2922 5.13806L15.4926 3.93767C15.8197 3.61065 16 3.17534 16 2.71239C16 2.24944 15.8197 1.81414 15.4926 1.48695Z"
                            fill="#4B49AC"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1000_6286">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
              {notesData?.files?.length! > 0 && (
                <div className="mt-6 border-t border-[#DDDBEE]">
                  <div className="px-4 py-6">
                    <div className="text-sm font-medium leading-6 text-gray-900 mb-3">
                      Files
                    </div>
                    <ul className="divide-y divide-[#DDDBEE] rounded-md border border-[#DDDBEE]">
                      {notesData?.files?.map((file: any, index: number) => (
                        <li
                          key={index}
                          className="flex items-center justify-between py-4 pl-4 pr-5 leading-6"
                        >
                          <div className="flex items-center w-full">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16 11L12 15M12 15L8 11M12 15V3M21 15V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V15"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <div className="ml-4 flex items-center justify-between gap-5 w-full">
                              <div className="float-start">
                                <button
                                  className="truncate font-medium w-full"
                                  onClick={() =>
                                    handleFileDownload(
                                      file.fileId,
                                      file.filename
                                    )
                                  }
                                >
                                  {file.filename}
                                </button>
                              </div>
                              <div className="flex-shrink-0 text-gray-400 w-1/3">
                                {getFileSize(file.size)}
                              </div>
                              <div className="w-1/3">
                                {moment(file?.dateModified).format(
                                  "MM/DD/YYYY hh:mm:ss"
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
            {/* Card code block end */}
          </div>
        </div>
      </div>
    </div>
  );
}
